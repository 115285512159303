@use "styles/colours";
@use "styles/spacing";
@use "styles/variables";

.warning-box {
    padding: spacing.$spacing-base;
    border: 1px solid colours.$flat-carrot;
    border-radius: variables.$border-radius;
    background-color: lighten(colours.$flat-carrot, 40%);
    display: flex;

    > svg {
        color: colours.$flat-carrot;
        font-size: 20px;
    }

    &__content {
        margin-left: spacing.$spacing-large;
    }

    &__content {
        > p {
            margin-top: 0;
        }
        > a,
        p > a {
            color: colours.$flat-carrot;
            text-decoration: underline;
            transition: color 150ms ease-out;

            &:hover {
                color: colours.$flat-pumpkin;
            }
        }
    }
}
