@use "styles/spacing";

.query-history-note-controls {
    display: flex;
    justify-content: flex-end;

    .button + .button {
        margin-left: spacing.$spacing-small;
    }
}
