@use "styles/spacing";
@use "styles/colours";

.login__version {
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;
    margin-top: spacing.$spacing-large;
    color: colours.$flat-concrete;
}
