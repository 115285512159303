@use "styles/spacing";
@use "styles/theme";

.navigation__sign-out {
    svg {
        @include theme.theme {
            color: theme.get(label);
        }
    }
    &-warnings {
        @include theme.theme {
            border-bottom: 1px solid theme.get(divider);
        }
        ul {
            margin-top: spacing.$spacing-large;
        }
        li {
            padding: spacing.$spacing-large;
            display: flex;
            align-items: center;
            p {
                margin: 0;
                padding-right: spacing.$spacing-small;
            }
            .button {
                white-space: nowrap;
                margin: 0;
                float: none;
            }
            @include theme.theme {
                border-top: 1px solid theme.get(divider);
            }
        }
    }
}
