/*
  Mixin for applying ellipsis to any element
*/
.button--split button {
  margin-top: 0px !important;
  border-radius: 3px 0px 0px 3px;
}
.button--split--no-dropdown-options button {
  border-radius: 3px;
}
.button--split__container {
  display: flex;
  align-items: center;
}
.button--split--drop-down-only button {
  cursor: unset !important;
}
.button--split--drop-down-only button[disabled] {
  border-top: 1px solid #3498db;
  border-bottom: 1px solid #3498db;
  border-left: 1px solid #3498db;
  color: #3498db;
  background-color: white;
}
.button--split--drop-down-only .button--positive:disabled {
  border-top: 1px solid #16a085;
  border-bottom: 1px solid #16a085;
  border-left: 1px solid #16a085;
  color: #16a085;
  background-color: white;
}
.button--split .drop-down-menu {
  color: white;
  border: none;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0px 3px 3px 0px;
}
.button--split .drop-down-menu button {
  all: unset;
  text-transform: uppercase;
  font-size: 1.1rem;
}
.button--split .drop-down-menu:not(.button--split-disabled):not([disabled]) {
  background-color: #3498db;
}
.button--split .drop-down-menu:not(.button--split-disabled):not([disabled]):hover div {
  background-color: #2980b9;
}
.button--split .drop-down-menu div:after {
  margin: 0;
}
.button--split .drop-down-menu div {
  height: 40px;
}
.button--split .drop-down-menu li,
.button--split .drop-down-menu div,
.button--split .drop-down-menu button {
  line-height: 40px;
  font-weight: bold;
}
.button--split .drop-down-menu ul {
  margin-top: 1px;
}
.button--split .drop-down-menu ul,
.button--split .drop-down-menu li {
  border-radius: 3px;
}
.button--split .drop-down-menu__icon {
  margin-left: 0;
}
.button--split--negative .drop-down-menu:not(.button--split-disabled):not([disabled]) {
  background-color: #e74c3c;
}
.button--split--negative .drop-down-menu:not(.button--split-disabled):not([disabled]):hover div {
  background-color: #c0392b;
}
.button--split--positive .drop-down-menu:not(.button--split-disabled):not([disabled]) {
  background-color: #16a085;
}
.button--split--positive .drop-down-menu:not(.button--split-disabled):not([disabled]):hover div {
  background-color: rgb(18.9175824176, 137.5824175824, 114.3653846154);
}

