@use "@edgetier/client-components/dist/styles/colours";

.close-modal-button {
    display: flex;
    align-items: center;
    font-size: 1.8rem;
    cursor: pointer;
    background-color: colours.$background;
    height: auto;
    padding: 0;
    transition: color 200ms;

    &:hover {
        color: colours.$negative;
    }
}
