@use "styles/theme";
@use "styles/variables";

.delete-attachment-button {
    .button--icon {
        font-size: variables.$font-size-small;
    }

    @include theme.theme {
        color: theme.get(negative);
    }

    &__disabled {
        @include theme.theme {
            color: darken(theme.get(disabled), 30%);
        }
        // The CSS between all the different attachments components is kind of messed up so it's hard to override
        // anything. Removing all pointer events is easier than a specificity war. We should refactor these componetns.
        pointer-events: none;
    }
}
