@use "styles/colours";
@use "styles/spacing";

@keyframes chat-messages__bounce {
    0%,
    25% {
        transform: translate3d(0, 0, 0);
    }

    50% {
        transform: translate3d(0, -#{spacing.$spacing-xx-small}, 0);
    }

    75%,
    100% {
        transform: translate3D(0, 0, 0);
    }
}

@mixin message-typing($delay) {
    animation: chat-messages__bounce 1s linear $delay infinite;
}

@keyframes chat-messages__typing-indicator__appear {
    from {
        opacity: 0;
        transform: translateX(10px) translateZ(0);
    }
}

.chat-messages__typing-indicator {
    margin-right: auto;
    animation: chat-messages__typing-indicator__appear 250ms ease-out;

    div {
        display: inline-block;
        height: spacing.$spacing-x-small;
        width: spacing.$spacing-x-small;
        border-radius: spacing.$spacing-x-small;
        background-color: var(--theme-colour);
        position: relative;
    }

    div:nth-of-type(1) {
        @include message-typing(250ms);
    }

    div:nth-of-type(2) {
        @include message-typing(500ms);
    }

    div:nth-of-type(3) {
        @include message-typing(750ms);
    }
}
