@use "styles/spacing";
@use "styles/colours";

.grid__header {
    text-align: left;
    background-color: colours.$background-tertiary;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: spacing.$spacing-small;
    border-bottom: 1px dashed colours.$divider;
}
