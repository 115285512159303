@use "@edgetier/client-components/dist/styles/colours";

.select-boolean {
    &__item--true {
        color: colours.$positive;
    }

    &__item--false {
        color: colours.$negative;
    }
}
