@use "../styles/variables";

.modal-header {
    display: flex;
    padding-bottom: calc(variables.$modal-padding / 2);

    &__content {
        flex: 1;
        // This allows truncated text in a flex child: https://css-tricks.com/flexbox-truncated-text/.
        min-width: 0;
    }
}
