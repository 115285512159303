@use "@edgetier/styles/sass/colours";
@use "@edgetier/styles/sass/spacing";

.interaction-icon {
    display: flex;
    width: 3 * spacing.$spacing-small;
    height: 3 * spacing.$spacing-small;
    align-items: center;
    justify-content: center;

    &__automated {
        --fa-primary-color: #{colours.$flat-silver};
        --fa-secondary-color: #{colours.$flat-concrete};
        --fa-secondary-opacity: 1;
    }

    &__call {
        color: #{colours.$flat-amethyst};
    }

    &__chat,
    &__messages {
        --fa-primary-color: #{colours.$flat-peter-river};
        --fa-secondary-color: #{colours.$flat-belize-hole};
        --fa-secondary-opacity: 1;
    }

    &__email {
        --fa-primary-color: #{colours.$flat-green-sea};
        --fa-secondary-color: #{colours.$flat-emerald};
        --fa-secondary-opacity: 0.4;
    }
    &__call-in,
    &__email-in {
        --fa-primary-color: #{colours.$flat-green-sea};
        --fa-secondary-color: #{colours.$flat-green-sea};
        --fa-secondary-opacity: 0.8;
    }

    &__call_out,
    &__email-out {
        --fa-primary-color: #{colours.$flat-alizarin};
        --fa-secondary-color: #{colours.$flat-alizarin};
        --fa-secondary-opacity: 0.8;
    }

    &__external {
        --fa-primary-color: #{colours.$flat-belize-hole};
        --fa-primary-opacity: 0.4;
        --fa-secondary-color: #{colours.$flat-belize-hole};
        --fa-secondary-opacity: 1;
    }

    &__notes {
        --fa-primary-color: #{colours.$flat-concrete};
        --fa-secondary-color: #{colours.$flat-silver};
        --fa-secondary-opacity: 1;
    }

    &__survey {
        --fa-primary-color: #{colours.$flat-midnight-blue};
        --fa-secondary-color: #{colours.$flat-midnight-blue};
        --fa-secondary-opacity: 0.4;
    }

    &__social-media {
        --fa-primary-color: #{colours.$flat-belize-hole};
        --fa-secondary-color: #{colours.$flat-peter-river};
        --fa-secondary-opacity: 1;
    }
}
