@use "theme";

.loading {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 2px;
    @include theme.theme {
        background-color: theme.get(loading-background);
        box-shadow: 1px 0 3px theme.get(loading-background);
    }
}
