@use "styles/colours";
@use "styles/spacing";
@use "styles/variables";

.change-skills {
    .warning-box {
        margin-top: spacing.$spacing-large;
        margin-bottom: spacing.$spacing-xx-small;
    }
}
