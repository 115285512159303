/*
  Mixin for applying ellipsis to any element
*/
.details-section {
  border-radius: 4px;
  box-shadow: 0 -1px 3px -1px rgba(0, 0, 0, 0.2), 0 2px 1px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
  padding-top: 12px;
  padding-bottom: 12px;
  background-color: white;
}
.details-section .accordion__accordion-item-header {
  padding-right: 12px;
  padding-left: 9px;
}
.details-section table {
  width: 100%;
}
.details-section h5,
.details-section td {
  line-height: 16px;
}
.details-section tr + tr td {
  border-top: 1px solid rgb(241.8727272727, 244.6363636364, 245.3272727273);
}
.details-section td,
.details-section .accordion__accordion-item-header__title {
  padding-top: 6px;
  padding-bottom: 6px;
}
.details-section td {
  vertical-align: middle;
}
.details-section td:not([colspan]):first-of-type {
  width: 40%;
  padding-right: 6px;
}
.details-section td:last-of-type {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}
.details-section table + h5 {
  margin-top: 12px;
  padding-top: 12px;
}
.details-section button {
  margin-top: 12px;
  margin-left: 12px;
}

