@use "variables";
@use "styles/breakpoints";

:root {
    --HEADER-HEIGHT: #{variables.$header-height};

    @include breakpoints.at(desktop-wide) {
        --HEADER-HEIGHT: #{variables.$header-height-wide};
    }
}
