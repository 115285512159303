.grid__table-footer {
  padding: 8px;
  display: flex;
  justify-content: flex-end;
}
.grid--is-in-box .grid__table-footer {
  border-top: 1px solid rgb(221.3181818182, 228.4090909091, 230.1818181818);
}

.grid:not(.grid--is-in-box) .grid__table-footer {
  box-shadow: inset 0 1px 2px -1px rgba(0, 0, 0, 0.1);
  background-color: #ecf0f1;
}

