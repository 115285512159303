@use "styles/spacing";
@use "styles/colours";
@use "styles/theme";
@use "styles/variables";

@keyframes enter {
    0% {
        opacity: 0;
        transform: translateY(-5%);
    }
    100% {
        transform: translateY(0);
    }
}

.login__background {
    height: calc(25% + 50px);
    position: fixed;
    background-position: center;
    background-size: contain;
    background-color: colours.$flat-peter-river;
    width: 100%;
    background-repeat: no-repeat;
}

.login {
    position: absolute;
    animation: enter 100ms ease-in;
    top: 25%;
    left: 50%;
    width: 500px;
    margin-left: -250px;
    opacity: 1;
    padding: spacing.$spacing-xxx-large;
    background-color: white;
    text-align: center;
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1), 0 -20px 100px rgba(colours.$flat-peter-river, 0.1);
    border-radius: 3px;
    border-bottom: 1px solid colours.$flat-silver;
    color: colours.$flat-midnight-blue;

    h1 {
        margin-bottom: spacing.$spacing-xx-large;
    }
    img {
        height: 22px;
        margin-bottom: spacing.$spacing-xx-large;
    }
    .button {
        display: block;
    }
    .button + .button {
        margin-top: spacing.$spacing-small;
    }
    &__loading {
        line-height: variables.$button-height;
        @include theme.theme {
            color: theme.get(label);
            background-color: theme.get(background-secondary);
        }
    }
}
