@use "@edgetier/styles/sass/colours";

@keyframes timer-bar__width {
    from {
        width: 100%;
    }

    to {
        width: 0%;
    }
}

@keyframes timer-bar__colour {
    70% {
        background-color: colours.$flat-green-sea;
    }

    80% {
        background-color: colours.$flat-alizarin;
    }

    100% {
        width: 0;
        background-color: colours.$flat-alizarin;
    }
}

.timer-bar {
    height: 6px;
    position: relative;

    &--animated &__inner {
        animation-name: timer-bar__width, timer-bar__colour;
        animation-timing-function: linear;
        animation-fill-mode: forwards;
    }

    &:not(.timer-bar--animated) &__inner {
        animation-name: timer-bar__width;
        animation-timing-function: linear;
        animation-fill-mode: forwards;
    }

    &__inner {
        height: 100%;
        left: 0;
        position: absolute;
        width: 100%;
    }
}
