.details-field-title {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 12px;
  padding-right: 12px;
  color: #16a085;
}
.details-field-title__loading {
  flex: 1;
}

