@use "variables";
@use "spacing";
@use "theme";

.old-modal {
    margin: spacing.$spacing-x-large;

    @include theme.theme {
        background-color: theme.get(background);
    }

    &__overlay {
        background-color: rgba(black, 0.25);
        min-height: 100vh;
    }

    &__close {
        @include theme.theme {
            background-color: theme.get(background);
        }
        line-height: 40px;
    }

    form {
        @include theme.theme {
            background-color: theme.get(background-secondary);
        }
        .field {
            padding-bottom: 0;
        }
    }

    button[type="submit"] {
        width: auto;
    }

    &__overlay:not(.old-modal__overlay--drawer) &__content {
        padding: variables.$modal-padding;
    }

    form {
        margin-left: -#{variables.$modal-padding};
        margin-right: -#{variables.$modal-padding};
        padding: variables.$modal-padding;

        button[type="submit"],
        .button--split--submit {
            margin-top: variables.$modal-padding * 2;
        }
    }
}

.ReactModal__Content {
    transition: transform 50ms ease-out;
}
