@use "@edgetier/styles/sass/theme";
@use "styles/colours";
@use "styles/spacing";
@use "styles/variables";

.ellipsis-menu {
    animation: appear 250ms ease-out;

    ul {
        background-color: colours.$pop-up-background;
        color: colours.$pop-up-color;
        border-radius: spacing.$spacing-xx-small;
        overflow: hidden;

        svg {
            color: colours.$flat-silver;
            margin-right: spacing.$spacing-small;
        }
    }

    &__item {
        display: flex;
        align-items: center;
        cursor: pointer;
        line-height: variables.$button-height * 1.25;
        padding: 0 spacing.$spacing-large;
        white-space: nowrap;
        &:not(&--disabled):hover {
            background-color: colours.$flat-midnight-blue;
            svg {
                color: colours.$primary;
            }
        }

        & + & {
            border-top: 1px solid colours.$flat-midnight-blue;
        }

        &--disabled {
            cursor: not-allowed;
            opacity: 0.25;
        }
    }

    &__trigger {
        cursor: pointer;
        display: flex;
        gap: spacing.$spacing-x-small;
        padding: spacing.$spacing-small;
        border-radius: 5px;
        transition: background-color 250ms ease-out;
        &:hover {
            background-color: colours.$divider;
            color: black;
        }
        color: colours.$neutral-variant;
    }

    &__arrow {
        &:has(+ ul:empty) {
            display: none;
        }
        svg path {
            fill: colours.$pop-up-background;
        }
    }

    &__label {
        font-weight: bold;
    }

    &--closed {
        display: none;
    }
}
