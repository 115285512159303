@use "styles/spacing";
@use "styles/theme";

.interaction-search__interaction-label {
    svg {
        display: none;
        margin-right: spacing.$spacing-x-small;
        @include theme.theme {
            color: theme.get(label);
        }
    }
}
