@use "@edgetier/client-components/dist/styles/colours";
@use "@edgetier/client-components/dist/styles/spacing";
@use "@edgetier/client-components/dist/styles/variables";

.popover-header {
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid colours.$flat-clouds-2;
    min-height: 54px;
    padding: 0 spacing.$spacing-x-large;
}
