@use "@edgetier/client-components/dist/styles/spacing";
@use "@edgetier/client-components/dist/styles/colours";

.metric-box-actions-menu {
    position: absolute;
    top: 0;
    right: 0;

    .ellipsis-menu__trigger {
        padding: spacing.$spacing-small;

        svg {
            margin: 0;
            font-size: 16px;
            color: colours.$color;
        }
    }
}
