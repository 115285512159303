.array-details-section:first-of-type {
  margin-top: 4px;
}
.array-details-section + .array-details-section {
  border-top: 1px solid rgb(241.8727272727, 244.6363636364, 245.3272727273);
}
.array-details-section .accordion__accordion-item-header__title {
  display: flex;
  justify-content: space-between;
}
.array-details-section .tooltip-warning__trigger {
  margin-left: auto;
  margin-right: 10px;
}

