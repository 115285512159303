@use "styles/root-variables" as *;
@use "styles/colours";
@use "breakpoint-slicer" as breakpointSlicer;

.client-navigation {
    height: 100%;
    text-align: center;
    background-color: colours.$background;
    border-right: 1px solid colours.$divider;
    display: flex;
    flex-direction: column;

    @include breakpointSlicer.from(desktop-wide) {
        flex: 0 0 90px;

        &__links a {
            word-spacing: 90px;
        }
    }

    &__links {
        flex: 1;
        display: flex;
        flex-direction: column;
    }

    &__logo {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 0 0 var(--HEADER-HEIGHT);
        border-bottom: 1px solid colours.$divider;

        img {
            height: 35px;
        }

        &--inverted {
            img {
                filter: invert(1);
            }
        }
    }
}

.sidebar {
    &__overlay {
        @include breakpointSlicer.to(desktop) {
            margin-left: 75px;
        }
        @include breakpointSlicer.at(desktop-wide) {
            margin-left: 90px;
        }
    }
}
