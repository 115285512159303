/**
 * Styles for React Select. The library uses a JS in CSS library so everything has to be overrided with important rules.
 */
@use "spacing";
@use "theme";
@use "variables";

.select__trigger__container {
    border-radius: variables.$border-radius;

    @include theme.theme {
        background-color: theme.get(background);
    }
}
