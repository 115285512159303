@use "styles/spacing";
@use "styles/colours";

.query-history-header {
    display: flex;
    gap: spacing.$spacing-base;
    align-items: center;

    &__expand-button-container {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex: 1;
    }

    &__expand-button {
        align-self: flex-end;
        margin-right: spacing.$spacing-xxx-small;
        font-size: 18px;
        line-height: 40px;
        background-color: white;
        cursor: pointer;
        svg {
            transition: color 350ms;
        }
        &:hover {
            svg {
                color: colours.$flat-belize-hole;
            }
        }
    }
}
