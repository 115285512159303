@use "styles/spacing";

.redact-form {
    p {
        margin-top: 0;
        margin-bottom: spacing.$spacing-large;
    }

    &__buttons {
        margin-left: auto;
    }

    button[type="reset"] {
        margin-right: spacing.$spacing-base;
    }
}
