@use "@edgetier/client-components/dist/styles/colours";
@use "@edgetier/client-components/dist/styles/spacing";

.role-actions-menu {
    .ellipsis-menu {
        animation: appear 250ms ease-out;
        z-index: 20;
        $button-height: 40px;
        ul {
            background-color: colours.$flat-wet-asphalt;
            color: colours.$flat-clouds;
            border-radius: spacing.$spacing-xx-small;
            padding: 0 spacing.$spacing-xx-small;

            svg {
                color: colours.$flat-clouds;
                margin-right: spacing.$spacing-small;
            }
        }

        &__item {
            display: flex;
            align-items: center;
            cursor: pointer;
            line-height: $button-height * 1.25;
            padding: 0 spacing.$spacing-large;

            &:not(&--disabled):hover {
                background-color: colours.$flat-midnight-blue;
                svg {
                    color: lighten(colours.$flat-peter-river, 10%);
                }
            }

            & + & {
                border-top: 1px solid colours.$flat-midnight-blue;
            }

            &--disabled {
                cursor: not-allowed;
                opacity: 0.25;
            }
        }

        &__trigger {
            display: inline-block;
            padding: spacing.$spacing-small;
            border-radius: 5px;
            cursor: pointer;

            color: darken(colours.$flat-asbestos, 10%);
            &:hover {
                background-color: darken(colours.$flat-clouds, 7%);
                color: colours.$flat-wet-asphalt;
            }
        }

        &__arrow {
            svg path {
                fill: colours.$flat-wet-asphalt;
            }
        }

        &--closed {
            display: none;
        }
    }
}
