////
/// Spacing defaults for all apps and component libraries
/// @group Spacing
////

$spacing-increment: 2px !default;
$spacing-xxx-small: $spacing-increment !default; // 2px
$spacing-xx-small: 2 * $spacing-increment !default; // 4px
$spacing-x-small: 3 * $spacing-increment !default; // 6px
$spacing-small: 4 * $spacing-increment !default; // 8px
$spacing-base: 5 * $spacing-increment !default; // 10px
$spacing-large: 6 * $spacing-increment !default; // 12px
$spacing-x-large: 8 * $spacing-increment !default; // 16px
$spacing-xx-large: 2 * $spacing-x-large !default; // 32px
$spacing-xxx-large: 3 * $spacing-x-large !default; // 48px

// Padding for all form fields.
$field-padding: $spacing-large !default;
