@use "styles/spacing";
@use "styles/theme";
@use "styles/variables";
@use "sass:math";

.timeline__item {
    $border: 2px;
    $dot-size: 16px;
    $margin: spacing.$spacing-large;
    $spacing: spacing.$spacing-x-large;

    label {
        cursor: pointer;
        display: flex;
        align-items: center;
        transition: background-color 150ms ease-out;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    }

    input {
        position: absolute;
        left: -9999px;
    }

    &--active &__dot::before,
    input:focus + label &__dot::before {
        transition: background-color 150ms ease-out;
        content: " ";
        width: 8px;
        height: 8px;
        margin-top: -4px;
        margin-left: -4px;
        border-radius: 100%;
        position: absolute;
        top: 50%;
        left: 50%;

        @include theme.theme {
            border: 2px solid theme.get(positive);
        }
    }

    &:not(.timeline-item--active) input:focus + label &__dot::before {
        @include theme.theme {
            background-color: theme.get(positive);
        }
    }

    label > svg {
        font-size: 18px;

        @include theme.theme {
            color: theme.get(medium);
        }
    }

    &__date {
        font-size: variables.$font-size-small;
        font-variant-numeric: tabular-nums;
    }

    &__guide {
        align-self: stretch;
        display: flex;
        align-items: center;
        position: relative;

        &::before {
            position: absolute;
            content: " ";
            left: 50%;
            margin-left: -#{math.div($border, 2)};
            top: 0;
            bottom: 0;
            width: $border;

            @include theme.theme {
                background-color: theme.get(positive);
            }
        }
    }

    &:first-of-type &__guide::before {
        top: 50%;
    }

    .timeline:not(.timeline__more-items) &:last-of-type &__guide::before {
        bottom: 50%;
    }
    .timeline__more-items &:last-of-type &__guide::before {
        bottom: 0;
    }

    &__dot {
        position: relative;
        margin: 0 spacing.$spacing-small;
        content: " ";
        width: $dot-size;
        height: $dot-size;
        border-radius: 100%;

        @include theme.theme {
            background-color: theme.get(background);
            border: $border solid theme.get(positive);
        }
    }

    &__time {
        margin-top: spacing.$spacing-xxx-small;

        @include theme.theme {
            color: theme.get(medium);
        }
    }

    &-interaction--active {
        @include theme.theme {
            background-color: theme.get(background-secondary);
        }
    }

    &-interaction--inactive {
        cursor: pointer;
    }

    &-interaction--inactive:hover &-dot::before {
        @include theme.theme {
            background-color: theme.get(medium);
        }
    }

    &-interaction--active &-dot {
        &::before {
            @include theme.theme {
                background-color: theme.get(positive);
            }
        }
    }
}
