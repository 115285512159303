@use "@edgetier/styles/sass/breakpoints";
@use "dynamic-form-field";
@use "character-count/character-count.scss";
@use "@edgetier/styles/sass/colours";

.dynamic-form {
    .field-container {
        display: flex;
        .field {
            flex: 1;
        }
        .preview-edit-button-container {
            align-self: center;
            button {
                margin-top: 0px !important;
            }
        }

        &--clickable {
            cursor: pointer;
            &:hover {
                .field,
                .select__trigger,
                .field > * {
                    background-color: colours.$flat-clouds;
                }
            }
        }
    }

    &--single-field {
        .field:last-of-type {
            padding-bottom: 0;
        }
    }

    @include breakpoints.from(desktop) {
        > fieldset[disabled] {
            &,
            * {
                cursor: not-allowed;
            }
        }
    }
}
