@use "styles/spacing";
@use "styles/theme";
@use "styles/variables";
@use "styles/root-variables" as *;
@use "styles/breakpoints";
@use "styles/colours";

.navigation {
    height: 100%;
    text-align: center;

    @include breakpoints.to(desktop) {
        flex: 0 0 75px;

        &__links a {
            word-spacing: 75px;
        }
    }

    @include breakpoints.at(desktop-wide) {
        flex: 0 0 90px;

        &__links a {
            word-spacing: 90px;
        }
    }

    &__links {
        height: calc(100% - var(--HEADER-HEIGHT));
        display: flex;
        flex-direction: column;

        > :last-child {
            margin-top: auto;
        }

        // Note: This group refers to settings + signout
        &-group {
            cursor: pointer;
            display: flex;
            flex-direction: column;
            max-height: 90px;
            flex: 1 1;

            @include theme.theme {
                border-top: 1px solid theme.get(divider);
            }

            // When settings + signout are present, we 
            // want the group to grow for 2 items
            &:has(li:nth-child(2)):not(:only-child) {
                flex: 2 1;
                max-height: 180px;
            }
        }

        a {
            transition: all 150ms ease-out;
            border-right: 3px solid transparent;
        }

        :not(.active):hover {
            border-right-color: colours.$flat-asbestos;
        }

        a,
        > div:last-of-type {
            max-height: 100px;
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            font-size: variables.$font-size-small;
            line-height: 1.3rem;
        }

        .active {
            cursor: default;
            border-right-color: colours.$flat-peter-river;
        }

        a:focus {
            outline: none;
        }
    }

    svg {
        font-size: 16px;
        display: block;
        margin-bottom: spacing.$spacing-xxx-small;
        transition: color 150ms ease-in;
    }

    &__logo {
        display: flex;
        justify-content: center;
        align-items: center;
        height: var(--HEADER-HEIGHT);

        img {
            height: 35px;
        }

        @include theme.theme {
            border-bottom: 1px solid theme.get(divider);
        }

        &--inverted {
            img {
                filter: invert(1);
            }
        }
    }

    a {
        position: relative;

        @include theme.theme {
            border-bottom: 1px solid theme.get(divider);
        }
    }

    @media (max-height: 600px) {
        .navigation-links {
            $icon-width: 30px;

            a,
            div {
                text-align: left;
                padding-left: $icon-width;
            }

            a > svg,
            div svg {
                text-align: center;
                width: $icon-width;
                position: absolute;
                left: 0;
                font-size: variables.$font-size-small;
            }
        }
    }

    @include theme.theme {
        background-color: theme.get(background);
        border-right: 1px solid theme.get(divider);

        a > svg {
            color: theme.get(primary);
        }

        &__links {

            a:hover .fa,
            a:focus .fa {
                color: theme.get(color);
            }
        }
    }
}