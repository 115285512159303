@use "styles/colours";
@use "styles/spacing";

.grid__menu-section {
    display: flex;
    flex-direction: column;

    & + & {
        margin-top: spacing.$spacing-base;
        padding-top: spacing.$spacing-base;
        border-top: 1px solid colours.$divider;
    }
}
