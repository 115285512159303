@use "styles/colours";
@use "styles/spacing";
@use "styles/theme";
@use "styles/variables";

.form-submission-net-promoter-score-field {
    &__value {
        display: flex;
        text-align: center;
        width: 100%;
        padding: spacing.$spacing-base;
        font-size: variables.$font-size-small;

        span {
            &:not(.form-submission-net-promoter-score-field__value--selected)::after {
                opacity: 0.65;
                height: 4px;
                width: 4px;
            }

            border-radius: 6px;
            flex: 1;
            padding: spacing.$spacing-xx-small 0;

            &::after {
                content: "";
                margin-top: spacing.$spacing-xx-small;
                margin-left: auto;
                margin-right: auto;
                display: block;
                border-radius: 100%;

                @include theme.theme {
                    background-color: theme.get(negative);
                }
            }
        }

        &--selected {
            font-weight: bold;
            opacity: 1;
            font-size: 18px;

            &::after {
                height: 8px;
                width: 8px;
            }
        }

        span:nth-of-type(7)::after,
        span:nth-of-type(8)::after {
            @include theme.theme {
                background-color: colours.$flat-sunflower;
            }
        }

        span:nth-of-type(9)::after,
        span:nth-of-type(10)::after {
            @include theme.theme {
                background-color: theme.get(positive);
            }
        }
    }
}
