@use "styles/spacing";
@use "styles/theme";

.query-history-interaction-content {
    &__interaction {
        flex: 1;
        overflow-y: auto;
    }

    @include theme.theme {
        background-color: theme.get(background-secondary);
    }

    &__buttons {
        padding-top: spacing.$spacing-small;
        padding-bottom: spacing.$spacing-small;
        display: flex;
        justify-content: flex-end;
        flex-wrap: wrap;
        gap: spacing.$spacing-small;
    }

    &__translation {
        justify-content: space-between;
    }
}
