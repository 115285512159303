@use "styles/variables";
@use "styles/colours";
@use "styles/spacing";

.navigation-link-indicator {
    position: absolute;
    right: spacing.$spacing-xx-small;
    text-align: center;
    font-weight: bold;
    box-shadow: 0 1px 5px rgba(black, 0.2);
    border-radius: 3px;
    padding: spacing.$spacing-xxx-small spacing.$spacing-xx-small;
    margin: 0;
    background-color: colours.$negative;
    color: colours.$background;

    &--top {
        top: spacing.$spacing-xx-small;
    }

    &--bottom {
        bottom: spacing.$spacing-xx-small;
    }
}
