/*
  Mixin for applying ellipsis to any element
*/
.navigation-link {
  flex: 1;
  max-height: 90px;
  list-style: none;
}
.navigation-link__inner {
  position: relative;
  cursor: pointer;
  height: 100%;
  border-right: 3px solid transparent;
  border-bottom: 1px solid #ecf0f1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 1.1rem;
  line-height: 1.3rem;
}
.navigation-link__inner:not(.active):hover {
  border-right-color: #bdc3c7;
}
.navigation-link__inner.active {
  border-right-color: #3498db;
}
.navigation-link__indicator {
  position: absolute;
  bottom: 4px;
  right: 4px;
  font-size: 1.1rem;
}
.navigation-link svg {
  font-size: 16px;
  display: block;
  margin-bottom: 2px;
  transition: color 150ms ease-in;
}
.navigation-link a svg {
  color: #3498db;
}

