.field > .field-label-tooltip {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
.field-label-tooltip__content {
  line-height: 18px;
  font-weight: bold;
}
.field-label-tooltip__content p:first-of-type {
  margin-top: 0;
}
.field-label-tooltip__content ul {
  display: block;
  list-style-type: disc;
  margin-left: 0;
  margin-right: 0;
  padding-left: 28px;
}
.field-label-tooltip__content li {
  margin-top: 2px;
}
.field-label-tooltip__content code {
  font-size: 10px;
  font-family: Monaco, Consolas, monospace;
  background-color: rgba(0, 0, 0, 0.3);
  outline: 2px solid rgba(0, 0, 0, 0.3);
}

