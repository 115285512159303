@use "styles/theme";

.location-details {
    &__maps-link {
        a {
            &:hover {
                @include theme.theme {
                    color: theme.get(positive);
                }
            }

            @include theme.theme {
                color: theme.get(primary);
            }
        }
    }
}
