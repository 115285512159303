@use "@edgetier/client-components/dist/styles/colours";
@use "styles/spacing";

.redact-form-options {
    display: flex;
    margin-top: spacing.$spacing-base;

    input {
        position: absolute;
        left: -9999px;
    }

    &__option {
        padding: spacing.$spacing-small;
        cursor: pointer;
        font-weight: bold;
        background-color: colours.$background;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
        border-bottom: 1px solid colours.$background-secondary;
        transition: all 250ms ease-out;

        &--is-active {
            background-color: colours.$background;
        }

        &--is-inactive {
            color: colours.$label;
            background-color: transparent;
        }
    }
}
