/*
  Mixin for applying ellipsis to any element
*/
.grid__pagination {
  margin-left: auto;
  padding-left: 10px;
}
.grid__pagination__content {
  display: flex;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.075);
}
.grid__pagination__button {
  text-align: center;
  transition: color 150ms ease-out;
  font-size: 16px;
  padding: 0 8px;
  line-height: 40px;
  cursor: pointer;
  background-color: white;
}
.grid__pagination__button:first-of-type {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.grid__pagination__button:last-of-type {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.grid__pagination__button--disabled {
  cursor: not-allowed;
  color: #ecf0f1;
}
.grid__pagination__button + .grid__pagination__button {
  margin-left: 1px;
}

