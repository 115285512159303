.theme-dark .toggle__state--disabled {
  color: #95a5a6;
}
.theme-light .toggle__state--disabled {
  color: #7f8c8d;
}
.theme-contrast .toggle__state--disabled {
  color: rgb(90.2107438017, 100.4979338843, 101.2892561983);
}

