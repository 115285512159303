@use "@edgetier/styles/sass/colours";
@use "@edgetier/styles/sass/spacing";

.dynamic-form__stars {
    .dynamic-form__rating__item label {
        padding: spacing.$spacing-base 0;
    }

    .dynamic-form__rating__item + .dynamic-form__rating__item label {
        border-left: 1px dashed rgba(black, 0.05);
    }

    .dynamic-form__rating__item--in-range,
    .dynamic-form__rating__item--selected {
        color: colours.$flat-sunflower;
    }

    .dynamic-form__rating__item--selected ~ .dynamic-form__rating__item {
        color: rgba(black, 0.3);
    }

    input[type="radio"]:focus + label {
        outline: 2px solid colours.$flat-asbestos;
    }
}
