@use "styles/variables";
@use "styles/colours";
@use "styles/spacing";

.navigation-link {
    flex: 1;
    max-height: variables.$navigation-link-height;
    list-style: none;

    &__inner {
        position: relative;
        cursor: pointer;
        height: 100%;
        border-right: 3px solid transparent;
        border-bottom: 1px solid colours.$background-tertiary;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        font-size: variables.$font-size-small;
        line-height: 1.3rem;

        &:not(.active):hover {
            border-right-color: colours.$flat-silver;
        }

        &.active {
            border-right-color: colours.$primary;
        }
    }

    &__indicator {
        position: absolute;
        bottom: spacing.$spacing-xx-small;
        right: spacing.$spacing-xx-small;
        font-size: variables.$font-size-small;
    }

    svg {
        font-size: 16px;
        display: block;
        margin-bottom: spacing.$spacing-xxx-small;
        transition: color 150ms ease-in;
    }

    a svg {
        color: colours.$primary;
    }
}
