@forward "@edgetier/styles/sass/colours";
@use "@edgetier/styles/sass/colours" as *;

$background-colour: lighten($flat-clouds, 2%);
$divider-colour: darken($flat-clouds, 5%);
$placeholder-colour: $flat-concrete;

$input-placeholder: #6d7779;

$message-in-datetime: #6d7779;
$message-out-datetime: #fafafa;
$message-out-background: #6a7677;

$loader-indicator-dark: #3b7ab7;
