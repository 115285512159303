/*
  Mixin for applying ellipsis to any element
*/
.navigation-link-indicator {
  position: absolute;
  right: 4px;
  text-align: center;
  font-weight: bold;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  padding: 2px 4px;
  margin: 0;
  background-color: #e74c3c;
  color: white;
}
.navigation-link-indicator--top {
  top: 4px;
}
.navigation-link-indicator--bottom {
  bottom: 4px;
}

