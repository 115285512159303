.boolean-icon {
  text-align: center;
  font-size: 16px;
}
.boolean-icon--true {
  color: #16a085;
}
.boolean-icon--false {
  color: #e74c3c;
}

