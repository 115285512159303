@use "styles/colours";
@use "styles/spacing";
@use "styles/variables";

.drop-down-menu {
    $menu-animation-duration: 300ms;
    $menu-border-radius: 2px;
    position: relative;
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.04);
    line-height: variables.$button-height - 2px;
    border-radius: 2px;
    z-index: 1;

    background-color: colours.$flat-clouds;
    border: 1px solid darken(colours.$flat-clouds, 7%);

    @keyframes drop-down-menu-appear {
        0% {
            opacity: 0;
            transform: scaleY(0) translateY(-10px);
        }
    }

    @keyframes drop-down-menu-disappear {
        0% {
            opacity: 1;
            transform: scaleY(1) translateY(0);
        }
    }

    div {
        cursor: pointer;
        padding: 0 (variables.$page-spacing - spacing.$spacing-small);
        transition: all $menu-animation-duration ease-out;
        border-top-right-radius: $menu-border-radius;
        border-top-left-radius: $menu-border-radius;
    }

    &__icon {
        margin-left: spacing.$spacing-base;
    }

    &:not(&--open):hover div {
        border-radius: $menu-border-radius;

        background-color: darken(colours.$flat-clouds, 7%);
    }

    &--open ul {
        opacity: 1;
        animation: drop-down-menu-appear $menu-animation-duration ease;
        transform: scaleY(1) translateY(0);
    }

    &:not(&--open) ul {
        opacity: 0;
        animation: drop-down-menu-disappear 0 ease;
        transform: scaleY(0) translateY(-10px);
    }

    &-open div,
    ul {
        background-color: colours.$flat-wet-asphalt;
        color: white;
    }

    ul {
        transform-origin: center top;
        position: absolute;
        top: 100%;
        right: 0;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.5);
        text-align: left;
        border-bottom-left-radius: $menu-border-radius;
        border-bottom-right-radius: $menu-border-radius;
        border-top-left-radius: $menu-border-radius;

        svg {
            color: colours.$flat-silver;
            margin-right: spacing.$spacing-small;
        }
    }

    li,
    li svg {
        transition: all 150ms ease-out;
    }

    li {
        line-height: variables.$button-height * 1.25;
        padding: 0 spacing.$spacing-large;
        white-space: nowrap;
        display: flex;
        align-items: center;
    }

    li:not(.drop-down-menu--disabled) {
        cursor: pointer;

        &:hover {
            background-color: colours.$flat-midnight-blue;

            svg {
                color: colours.$flat-peter-river;
            }
        }
    }

    li + li {
        border-top: 1px solid colours.$flat-midnight-blue;
    }

    &--disabled {
        cursor: not-allowed;
        opacity: 0.25;

        div {
            cursor: not-allowed;
        }
    }
}
