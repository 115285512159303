@use "styles/spacing";
@use "styles/theme";

.email-html {
    h2 {
        overflow-y: auto;
        white-space: pre-wrap;
        overflow-wrap: break-word;
        word-wrap: break-word;
        -ms-word-break: break-all;
        word-break: break-word;
    }

    iframe {
        height: 800px;
        width: 100%;

        @include theme.theme {
            border: 1px solid theme.get(divider);
        }
    }

    .toggle-translation__button {
        margin-top: spacing.$spacing-base;
    }
}
