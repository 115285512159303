@use "variables";
@use "spacing";
@use "theme";

.hot-toast-icon {
    &--error {
        @include theme.theme {
            --fa-primary-color: white;
            --fa-secondary-color: #{theme.get(negative)};
            --fa-secondary-opacity: 1;
        }
    }

    &--success {
        @include theme.theme {
            --fa-primary-color: white;
            --fa-secondary-color: #{theme.get(positive)};
            --fa-secondary-opacity: 1;
        }
    }

    &--info {
        @include theme.theme {
            --fa-primary-color: white;
            --fa-secondary-color: #{theme.get(primary)};
            --fa-secondary-opacity: 1;
        }
    }
}
