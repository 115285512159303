@use "styles/colours";

.grid__expand-button {
    font-size: 18px;
    cursor: pointer;
    transition: color 150ms ease-out;
    color: colours.$label;
    &:hover {
        color: colours.$positive;
    }
}
