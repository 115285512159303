.accordion__accordion-item-header {
  display: flex;
  align-items: center;
  min-width: 0;
  justify-content: space-between;
}
.accordion__accordion-item--can-open .accordion__accordion-item-header {
  cursor: pointer;
}
.accordion__accordion-item-header__title {
  flex: 1;
  display: flex;
  align-items: center;
  padding-top: 2px;
  padding-bottom: 2px;
  line-height: 1.82rem;
  min-width: 0;
}
.accordion__accordion-item-header .expand-collapse-arrow {
  padding: 8px;
}

