@use "styles/colours";
@use "styles/spacing";

.tooltip-warning {
    $size: 24px;

    &__icon {
        height: $size;
        width: $size;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 4px;
        color: colours.$flat-pomegranate;
    }
}
