/*
  Mixin for applying ellipsis to any element
*/
.grid__filters {
  display: flex;
  flex: 1;
}
.grid__filters .field-inline {
  width: 280px;
  height: auto;
  min-height: 40px;
}
.grid__filters .field-inline + .field-inline {
  margin-left: 8px;
}

