@use "styles/spacing";
@use "styles/theme";
@use "styles/variables";

.call-interactions {
    margin: spacing.$spacing-x-large;
    border-radius: variables.$box-border-radius;
    box-shadow: variables.$box-box-shadow;
    padding: (spacing.$spacing-x-large - spacing.$spacing-base) spacing.$spacing-x-large;

    @include theme.theme {
        background-color: theme.get(background);
    }

    table {
        border-collapse: collapse;
    }

    &__interaction {
        td {
            line-height: variables.$line-height-base;
            vertical-align: middle;
            text-align: left;
            padding: spacing.$spacing-base 0;
        }

        td:first-of-type {
            padding-right: spacing.$spacing-base;
            position: relative;
            padding-left: spacing.$spacing-x-large;

            &::before {
                content: " ";
                top: -#{spacing.$spacing-base};
                left: 2px;
                bottom: -#{spacing.$spacing-base};
                position: absolute;

                @include theme.theme {
                    border-left: 1px solid rgba(theme.get(positive), 0.25);
                }
            }

            &::after {
                content: " ";
                height: 5px;
                width: 5px;
                position: absolute;
                top: 50%;
                left: 0;
                margin-top: -2.5px;
                border-radius: 3px;

                @include theme.theme {
                    background-color: theme.get(positive);
                }
            }

            @include theme.theme {
                color: theme.get(positive);
            }
        }
    }

    tbody tr:first-of-type td:first-of-type:before {
        top: 50%;
    }

    tbody tr:last-of-type td:first-of-type:before {
        bottom: 50%;
    }
}
