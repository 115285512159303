@use "styles/spacing";
@use "styles/theme";

.navigation__chat-link {
    &--highlight {
        @include theme.theme {
            color: theme.get(negative);
        }
    }

    &-invites__icon {
        @include theme.theme {
            margin: 0;
            padding: spacing.$spacing-xxx-small 0;
            font-size: spacing.$spacing-base;
            background-color: theme.get(negative);
            color: theme.get(background);
        }
    }
}
