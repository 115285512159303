.common-details {
    &__interaction-url {
        max-width: 0;
        width: 100%;

        .details-field-value__value {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
}
