@use "styles/colours";
@use "styles/spacing";

.grid__menu-sort-options {
    display: flex;
    flex-direction: column;
    svg {
        margin-right: spacing.$spacing-xxx-small;
        color: colours.$medium;
    }
}
