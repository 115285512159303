@use "@edgetier/styles/sass/colours";
@use "@edgetier/styles/sass/spacing";
@use "@edgetier/styles/sass/variables";

.tag-component {
    font-size: 13px;
    font-weight: bold;
    display: inline-block;
    color: colours.$flat-white;
    padding: spacing.$spacing-xx-small;
    border-radius: variables.$border-radius;
    text-wrap: nowrap;

    &.green {
        background-color: colours.$flat-nephritis;
    }
    &.blue {
        background-color: colours.$flat-peter-river;
    }
    &.lightTeal {
        background-color: colours.$flat-turquoise;
    }
    &.darkTeal {
        background-color: colours.$flat-green-sea;
    }
    &.lightOrange {
        background-color: colours.$flat-orange;
    }
    &.mediumOrange {
        background-color: colours.$flat-carrot;
    }
    &.darkOrange {
        background-color: colours.$flat-pumpkin;
    }
    &.red {
        background-color: colours.$flat-pomegranate;
    }
}
