@use "styles/spacing";
@use "@edgetier/styles/sass/theme";
@use "styles/variables";
@use "styles/colours";

@keyframes chat-message-appear {
    0% {
        transform: scale3d(0, 0, 1);
    }

    80% {
        transform: scale3d(1.05, 1.05, 1);
    }

    100% {
        transform: scale3d(1, 1, 1);
    }
}

$message-width: 75%;
$spacing: spacing.$spacing-base;
$border-radius: 14px;

.chat-messages__message {
    animation-duration: 180ms;
    animation-iteration-count: 1;
    animation-name: chat-message-appear;
    animation-timing-function: ease-out;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;

    .attachments,
    a {
        overflow-wrap: break-word;
        word-wrap: break-word;
        -ms-word-break: break-all;
        word-break: break-all;
        word-break: break-word;
        overflow-wrap: break-word;
    }

    &:first-of-type {
        margin-top: auto;
    }

    &--error &__container {
        text-decoration: line-through;
        color: rgba(black, 0.2);
    }

    &--typing &__container {
        padding: spacing.$spacing-large;
    }

    &__container {
        padding: spacing.$spacing-large spacing.$spacing-large spacing.$spacing-small spacing.$spacing-large;
        white-space: pre-wrap;
        word-wrap: break-word;
        overflow-wrap: break-word;
    }

    &__extras {
        display: flex;
        font-size: variables.$font-size-small;
        align-items: center;
        gap: spacing.$spacing-base;
        padding: spacing.$spacing-xx-small 0;
    }

    p {
        margin-top: 0;
        white-space: pre-wrap;
        &:focus {
            outline: none;
        }
    }

    &--out {
        transform-origin: bottom right;
        align-items: flex-end;
    }

    &--in {
        align-items: flex-start;
        transform-origin: bottom left;
    }

    &--out &__container,
    &--in &__container {
        border-radius: 4px;
    }

    &--out &__container,
    &--in &__container,
    .dynamic-form {
        max-width: $message-width;
        min-width: 120px;
    }

    &--in &__container {
        border-top-left-radius: $border-radius;
        border-top-right-radius: $border-radius;
    }

    &--in:not(.chat-messages__message--has-form) &__container,
    &--in.chat-messages__message--single-button-field &__container {
        border-bottom-right-radius: $border-radius;
    }

    &--status {
        display: block;
        text-align: center;
        transform-origin: center center;
    }

    &--status &__container {
        display: inline-block;
        min-width: 80%;
        padding: spacing.$spacing-base;
        border-radius: 10px;
    }

    &--sending {
        opacity: 0.5;
    }

    & + & &__container {
        margin-top: spacing.$spacing-large;
    }

    // Consecutive messages of the same type are closely grouped.
    &--in + &--in &__container,
    &--out + &--out &__container,
    &--status + &--status &__container {
        margin-top: spacing.$spacing-xxx-small;
    }

    &--system,
    &--error {
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        flex-shrink: 0;
        svg {
            margin-right: spacing.$spacing-base;
        }
    }

    &--has-form &__container {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    &--has-form &__container,
    .dynamic-form {
        min-width: $message-width;
    }

    p + &__extras {
        margin-top: spacing.$spacing-xxx-small;
    }

    .attachments {
        margin: spacing.$spacing-small 0;
    }

    &--single-button-field .dynamic-form {
        padding-top: spacing.$spacing-small;
    }

    &:not(.chat-messages__message--single-button-field) .dynamic-form {
        clear: both;

        > fieldset {
            $border-width: spacing.$spacing-increment + 1;
            display: block;
            padding: spacing.$spacing-large spacing.$spacing-large 0 spacing.$spacing-large;
            border: 0 solid white;
            border-left-width: $border-width;
            border-right-width: $border-width;

            & .field {
                padding-bottom: 0;
            }

            &:last-of-type {
                border-bottom-width: $border-width;
                border-bottom-left-radius: $border-radius;
                border-bottom-right-radius: $border-radius;
                margin-bottom: spacing.$spacing-base;

                & .field {
                    padding-bottom: spacing.$spacing-large;
                }
            }
        }
    }

    &--out &__container {
        border-top-left-radius: $border-radius;
        border-top-right-radius: $border-radius;
        border-bottom-left-radius: $border-radius;
        color: white;

        a {
            $link-colour: white;
            color: $link-colour;
            &:hover {
                color: darken($link-colour, 20%);
            }
        }

        .toggle-translation__button--inactive {
            @include theme.theme {
                color: rgba(theme.get(background), 0.6);
            }
        }

        @include theme.theme {
            background-color: var(--CHAT-MESSAGE-COLOUR, theme.get(positive));
        }
    }

    &--error svg {
        @include theme.theme {
            color: theme.get(negative);
        }
    }

    &--system svg {
        @include theme.theme {
            color: theme.get(color-secondary);
        }
    }
}
