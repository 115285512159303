@use "styles/variables";
@use "styles/colours";

.grid__pagination-numbers {
    height: variables.$button-height;
    width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: variables.$font-size-small;
    margin: 0 1px;

    background-color: colours.$background;
}
