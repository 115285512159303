.grid td.grid__cell--has-link {
  position: relative;
  height: inherit;
  padding: 0 10px;
}
.grid td.grid__cell .grid__cell__link {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 12px 0;
}

.grid:not(.grid--is-in-box) td.grid__cell--has-link {
  padding: 0;
}
.grid:not(.grid--is-in-box) td.grid__cell .grid__cell__link {
  padding: 8px;
}

