/*
  Mixin for applying ellipsis to any element
*/
.box-subheading {
  font-size: 1.1rem;
  margin-top: 6px;
  color: rgb(102.2231404959, 113.8801652893, 114.7768595041);
  font-weight: normal;
}

