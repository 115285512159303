/*
  Mixin for applying ellipsis to any element
*/
.popover-header {
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #dde4e6;
  min-height: 54px;
  padding: 0 16px;
}

