@import "styles/spacing";
@import "styles/theme";
@import "styles/spacing";
@import "styles/colours";

.assignment-details-field {
    display: flex;
    flex-direction: row;
    padding: $spacing-x-small $spacing-large;
    align-items: center;
    margin-bottom: $spacing-x-small;
    border-bottom: 1px solid $flat-clouds;
    gap: $spacing-small;

    &:last-of-type {
        margin-bottom: 0;
        border-bottom: 0;
    }

    &__group {
        display: flex;
        flex-direction: column;
        gap: $spacing-xx-small;
        flex: 1;
    }

    & > span > .button.button--negative {
        margin: 0;
        flex: 0 0 auto;
        font-size: 1.15em;
    }

    &__text {
        word-break: break-word;
        line-height: normal;
        @include theme {
            color: get(neutral);
        }
    }

    &__date {
        display: flex;
        flex-direction: row;
        gap: $spacing-xx-small;
        & > div:first-child {
            font-weight: 600;
        }
    }

    &__status {
        @include theme {
            color: get(neutral);
        }
        &--active {
            @include theme {
                color: get(primary);
            }
        }
    }
}
