.grid__expand-button {
  font-size: 18px;
  cursor: pointer;
  transition: color 150ms ease-out;
  color: #7f8c8d;
}
.grid__expand-button:hover {
  color: #16a085;
}

