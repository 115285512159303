@use "styles/colours";
@use "styles/spacing";

.send-email-button {
    &__buttons {
        display: flex;
        flex-direction: row;
        gap: spacing.$spacing-x-small;
        width: 100%;

        & button:first-child {
            margin-right: auto;
        }
    }

    &__container {
        display: flex;
        flex-direction: column;
        gap: spacing.$spacing-large;
        text-align: center;

        & p {
            color: colours.$flat-asbestos;
            margin-top: 0;
        }
        & svg {
            color: colours.$flat-carrot;
        }
    }
}
