@use "@edgetier/styles/sass/spacing";

.field__character-count {
    float: right;
    padding-right: spacing.$spacing-large;
    font-size: 12px;
    &--limit {
        font-weight: bold;
        color: tomato;
    }
}
