@use "@edgetier/styles/sass/spacing";

.permission-summary {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: spacing.$spacing-base;
    min-height: 2 * spacing.$spacing-x-large;

    &__description-info {
        padding: spacing.$spacing-xxx-small;
    }

    &__title {
        line-height: 1.2;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
}
