@use "@edgetier/styles/sass/spacing";

.toggle-translation__button {
    font-weight: bold;
    white-space: nowrap;

    &--disabled {
        opacity: 0.3;
        cursor: not-allowed;
        pointer-events: none;
    }

    label {
        cursor: pointer;
        display: flex;
        align-items: center;
    }

    label,
    li {
        transition: opacity 250ms ease-out;
    }

    li {
        display: inline-block;
        position: relative;
    }

    svg,
    li + li {
        margin-left: spacing.$spacing-xxx-small;
    }

    input {
        display: none;
    }
}
