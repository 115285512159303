.grid__header {
  text-align: left;
  background-color: #ecf0f1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  border-bottom: 1px dashed rgb(221.3181818182, 228.4090909091, 230.1818181818);
}

