/*
  Mixin for applying ellipsis to any element
*/
.metric-box {
  text-align: center;
}
.metric-box__header {
  background-color: rgb(241.8727272727, 244.6363636364, 245.3272727273);
  align-items: center;
  border-radius: 6px;
  margin-bottom: 10px;
  height: 75px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}
.metric-box__tooltip {
  text-align: center;
  line-height: 1.69rem;
}
.metric-box svg {
  color: #3498db;
  font-size: 22px;
  margin-bottom: 10px;
}
.metric-box .box-content {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.metric-box__values {
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.metric-box__agent-value {
  font-weight: bold;
  font-size: 22px;
}
.metric-box__overall-value {
  margin-top: 2px;
  font-size: 1.1rem;
}
.metric-box__title {
  padding: 0 2px;
}

