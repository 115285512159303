/*
  Mixin for applying ellipsis to any element
*/
:root {
  --HEADER-HEIGHT: 55px;
}
@media (min-width: 1600px) {
  :root {
    --HEADER-HEIGHT: 65px;
  }
}

.client-navigation {
  height: 100%;
  text-align: center;
  background-color: white;
  border-right: 1px solid rgb(221.3181818182, 228.4090909091, 230.1818181818);
  display: flex;
  flex-direction: column;
}
@media (min-width: 1600px) {
  .client-navigation {
    flex: 0 0 90px;
  }
  .client-navigation__links a {
    word-spacing: 90px;
  }
}
.client-navigation__links {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.client-navigation__logo {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 var(--HEADER-HEIGHT);
  border-bottom: 1px solid rgb(221.3181818182, 228.4090909091, 230.1818181818);
}
.client-navigation__logo img {
  height: 35px;
}
.client-navigation__logo--inverted img {
  filter: invert(1);
}

@media (max-width: 1599px) {
  .sidebar__overlay {
    margin-left: 75px;
  }
}
@media (min-width: 1600px) {
  .sidebar__overlay {
    margin-left: 90px;
  }
}

