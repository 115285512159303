@use "styles/spacing";
@use "styles/variables";

.grid__filters {
    display: flex;
    flex: 1;

    .field-inline {
        width: 280px;
        height: auto;
        min-height: variables.$button-height;
    }

    .field-inline + .field-inline {
        margin-left: spacing.$spacing-small;
    }
}
