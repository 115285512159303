@use "styles/variables";
@use "styles/spacing";
@use "styles/colours";
@use "styles/breakpoints";

@keyframes enter {
    0% {
        opacity: 0;
        transform: translateY(-5%);
    }

    100% {
        transform: translateY(0);
    }
}

.client-login {
    position: absolute;
    animation: enter 100ms ease-in;
    top: 25%;
    left: 50%;
    width: 500px;
    margin-left: -250px;
    opacity: 1;
    text-align: center;
    color: colours.$flat-midnight-blue;

    @include breakpoints.to(mobile) {
        top: 50%;
        // Plus 13px for the height of the version numnber.
        transform: translateY(calc(-50% + 13px));
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 90%;
    }

    img {
        height: 22px;
    }

    &__title {
        @include breakpoints.to(mobile) {
            font-size: 16px;
        }
    }

    .button {
        display: block;
        width: 100%;
    }

    .button + .button {
        margin-top: spacing.$spacing-small;
    }

    &__background {
        height: calc(25% + 50px);
        position: fixed;
        background-position: center;
        background-size: contain;
        background-color: colours.$flat-peter-river;
        width: 100%;
        background-repeat: no-repeat;

        @include breakpoints.to(mobile) {
            height: 50%;
            background-size: 75%;
        }
    }

    &__container {
        display: flex;
        flex-direction: column;
        gap: spacing.$spacing-xx-large;

        padding: spacing.$spacing-xxx-large;
        background-color: white;
        box-shadow:
            0 2px 2px rgba(0, 0, 0, 0.1),
            0 -20px 100px rgba(colours.$flat-peter-river, 0.1);
        border-radius: 8px;
        border-bottom: 1px solid colours.$flat-silver;

        @include breakpoints.to(mobile) {
            padding: spacing.$spacing-xx-large;
            gap: spacing.$spacing-x-large;
        }
    }

    &__version {
        color: colours.$label;
        margin-top: spacing.$spacing-small;
    }
}
