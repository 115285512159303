@use "@edgetier/client-components/dist/styles/colours";

.information-field {
    padding: 16px;
    margin-bottom: 6px;
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: white;
    border-radius: 4px;

    font-size: 13px;
    font-weight: 400;
    line-height: 18px;

    .link {
        text-decoration: underline;
        color: colours.$flat-peter-river;
    }
}
