@use "styles/variables";
@use "styles/colours";
@use "styles/spacing";

.category-navigation__item {
    flex: 1;
    max-height: variables.$navigation-link-height;
    list-style: none;
    position: relative;

    &__inner {
        cursor: pointer;
        height: 100%;
        border-bottom: 1px solid darken(colours.$background-tertiary, 5%);
        border-right: 3px solid transparent;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        font-size: variables.$font-size-small;
        line-height: 1.3rem;
        position: relative;

        &:not(&--active):hover {
            border-right-color: colours.$flat-silver;
        }

        &--active {
            border-right-color: colours.$primary;
        }
    }

    &__label {
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 80px;
        line-height: normal;
    }

    svg {
        font-size: 16px;
        display: block;
        margin-bottom: spacing.$spacing-xxx-small;
        transition: color 150ms ease-in;
        color: colours.$primary;
    }

    &:last-child {
        border-bottom: 0;
        border-top: 1px solid darken(colours.$background-tertiary, 5%);
    }

    &__caret {
        position: absolute;
        top: 35%;
        right: 0;

        & > svg {
            color: colours.$flat-concrete;
        }
    }

    &__indicator {
        position: absolute;
        bottom: spacing.$spacing-xx-small;
        right: spacing.$spacing-xx-small;
        font-size: variables.$font-size-small;
    }
}

.category-navigation__menu {
    min-width: variables.$category-navigation-width;

    ul {
        background-color: colours.$background;
        padding: spacing.$spacing-xx-small 0;
        border-radius: variables.$box-border-radius;
        box-shadow: variables.$box-box-shadow;
        border: 1px solid colours.$background-tertiary;
        margin-left: spacing.$spacing-base;
    }

    & .navigation-link {
        &__inner {
            border-right: 0;
            border-left: 2px solid transparent;
            justify-content: flex-start;
            align-items: center;
            flex-direction: row;
            gap: spacing.$spacing-x-small;
            padding: spacing.$spacing-x-large spacing.$spacing-large;

            &:not(.active):hover {
                border-left-color: colours.$flat-silver;
            }

            &.active {
                border-left-color: colours.$primary;
            }

            & > div {
                margin-bottom: 2px;
            }
        }

        &__indicator {
            bottom: unset;
            top: unset;
        }

        &:last-child {
            .navigation-link__inner {
                border-bottom: 0;
            }
        }
    }
}
