/*
  Mixin for applying ellipsis to any element
*/
.labeled-field {
  position: relative;
}
.labeled-field.field {
  margin-bottom: 0;
  background-color: transparent;
  box-shadow: none;
}
.theme-dark .labeled-field.field {
  background-color: transparent;
}
.theme-light .labeled-field.field {
  background-color: transparent;
}
.theme-contrast .labeled-field.field {
  background-color: transparent;
}
.labeled-field__label, .field > .labeled-field__label {
  font-size: 1.1rem;
  margin-bottom: 6px;
  padding: 0;
  font-weight: 700;
  color: #7f8c8d;
  text-transform: uppercase;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  overflow: hidden;
  padding-right: 16px;
}

