@use "@edgetier/styles/sass/colours";
@use "@edgetier/styles/sass/spacing";

.dynamic-form__net-promoter-score {
    text-align: center;

    &__item {
        position: relative;
        padding: spacing.$spacing-base 0;
        font-size: 12px;
        transition: all 150ms ease-out;
    }

    &__labels {
        padding: spacing.$spacing-xxx-small spacing.$field-padding 0 spacing.$field-padding;
        display: flex;
        justify-content: space-between;
        font-size: 12px;

        svg {
            margin: 0 spacing.$spacing-base;
            opacity: 0.35;
        }
    }

    .dynamic-form__rating__item + .dynamic-form__rating__item .dynamic-form__net-promoter-score__item {
        border-left: 1px dashed rgba(black, 0.05);
    }

    .dynamic-form__rating__item--in-range .dynamic-form__net-promoter-score__item {
        background-color: rgba(colours.$flat-sunflower, 0.15);
    }

    .dynamic-form__rating__item--selected .dynamic-form__net-promoter-score__item {
        background-color: rgba(colours.$flat-sunflower, 0.3);
        font-size: 15px;
        font-weight: bold;
    }

    .dynamic-form__rating__item--selected ~ .dynamic-form__rating__item {
        color: rgba(black, 0.3);
    }

    input[type="radio"]:focus + label {
        outline: 2px solid colours.$flat-asbestos;
    }
}
