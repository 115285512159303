@use "styles/spacing";
@use "styles/theme";

.query-history__interaction {
    display: flex;
    overflow: hidden;
    height: 100%;

    .spinner {
        height: 100%;
    }

    &__content {
        flex: 0 0 60%;
        display: flex;
        flex-direction: column;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;
        box-shadow: 0 2px 1px -1px rgba(black, 0.1);
    }

    &__details {
        overflow: auto;
        flex: 0 0 40%;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        padding: spacing.$spacing-x-large;

        &::-webkit-scrollbar-track {
            @include theme.theme {
                border-left: 1px solid theme.get(background);
            }
        }

        @include theme.theme {
            border-left: 1px solid theme.get(background);
            background-color: theme.get(background-secondary);
        }
    }

    .query-history-interaction-content__buttons,
    .chat-events-filters,
    .query-history-interaction-content__translation {
        padding-left: spacing.$spacing-x-large;
        padding-right: spacing.$spacing-x-large;

        @include theme.theme {
            border-top: 1px solid theme.get(background);
        }
    }

    .chat-events-filters,
    .query-history-interaction-content__translation {
        display: flex;
        padding-bottom: spacing.$spacing-x-large;
        padding-top: spacing.$spacing-x-large;
    }
}
