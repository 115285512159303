@use "styles/colours";
@use "styles/spacing";
@use "styles/theme";
@use "styles/variables";
@use "styles/breakpoints";

.query-history-timeline-item {
    display: flex;
    flex-direction: column;
    min-height: calc(#{variables.$line-height-base * 3} + #{spacing.$spacing-x-small * 3});
    flex: 1 1 0;
    padding: spacing.$spacing-base;
    margin: {
        top: spacing.$spacing-small;
        bottom: spacing.$spacing-small;
        left: spacing.$spacing-base;
    }
    line-height: variables.$line-height-base;
    border: 1px solid transparent;
    position: relative;
    box-shadow: 0 1px 1px rgba(black, 0.05);
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word;

    &__header {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: spacing.$spacing-xx-small;
        position: relative;
        flex-wrap: wrap;
        gap: spacing.$spacing-x-small;

        &__subject {
            flex: 1 1 55%;
            word-break: break-word;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            overflow: hidden;
            line-height: 1.3;

            @include breakpoints.to(desktop) {
                order: 2;
            }
        }
    }

    &__icon {
        & > svg {
            font-size: 18px;

            @include theme.theme {
                color: theme.get(medium);
            }
        }
    }

    & .split-timestamp {
        font-size: spacing.$spacing-large;
        margin-left: auto;
        margin-right: spacing.$spacing-large;

        &__time {
            @include theme.theme {
                color: theme.get(label);
            }
        }
    }

    &__type {
        font-size: variables.$font-size-base;
        font-weight: 500;

        @include theme.theme {
            color: theme.get(primary);
        }
    }

    @include theme.theme {
        border-radius: 4px;
        background-color: theme.get(background);
        border: 1px solid theme.get(divider);
    }

    &--current {
        @include theme.theme {
            border-color: theme.get(positive);
        }
    }

    &__body {
        font-size: 12px;
        line-height: 1.3;
        word-break: break-word;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
    }
}
