@use "@edgetier/client-components/dist/styles/colours";
@use "@edgetier/client-components/dist/styles/spacing";

.select-info-message {
    max-width: 100%;
    box-sizing: border-box;

    &__content {
        display: flex;
        align-items: center;
        gap: spacing.$spacing-x-large;
        padding: spacing.$spacing-x-large;
        border-left: spacing.$spacing-xx-small solid colours.$flat-peter-river;

        .info-icon {
            font-size: spacing.$spacing-x-large;
            color: colours.$flat-peter-river;
        }
    }
}
