@use "styles/theme";
@use "styles/spacing";

.query-history-thread {
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: stretch;

    &__content {
        display: flex;
        flex: 1;
        overflow: hidden;
    }

    // Query state button.
    .button--split__container {
        display: flex;
        justify-content: flex-end;
    }

    .query-history-timeline {
        overflow-y: auto;
        height: 100%;
        position: relative;

        form {
            margin: 0;
            padding: 0;

            @include theme.theme {
                background-color: theme.get(background);
            }
        }

        .timeline__item--active label {
            @include theme.theme {
                background-color: theme.get(background-secondary);
            }
        }

        .timeline__item label {
            padding: 0 spacing.$spacing-x-large;
            height: 50px;
        }
    }
}
