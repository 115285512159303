@use "styles/spacing";

.query-history-timeline {
    > button {
        margin: spacing.$spacing-x-small;
        width: calc(100% - #{spacing.$spacing-x-small * 2});
    }
    &__current {
        &--empty {
            text-align: center;
            font-style: italic;
            font-weight: 400;
            font-size: spacing.$spacing-large;
        }
    }
}
