@use "styles/spacing";
@use "styles/colours";

.details-field-value {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: spacing.$spacing-large;

    &__copy-icon {
        pointer-events: none;
        position: absolute;
        right: 100%;
        margin-right: spacing.$spacing-base;
        opacity: 0;
        transition: opacity 250ms ease-out;
        color: colours.$positive;
    }

    &--can-copy {
        position: relative;
    }

    &--can-copy:hover &__copy-icon {
        opacity: 1;
    }

    &__value--loading {
        flex: 1;
    }

    &--can-copy &__value {
        cursor: pointer;
        outline: 4px solid transparent;
        transition: all 250ms ease-out;

        &:hover,
        &:hover * {
            background-color: colours.$positive;
            color: colours.$background;
            outline-color: colours.$positive;
        }

        &:active {
            background-color: colours.$pop-up-background;
            outline-color: colours.$pop-up-background;
        }
    }
}
