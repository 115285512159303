@use "@edgetier/styles/sass/spacing";

.chulk-load-error-fallback {
    &__description {
        font-size: 1.1em;
        margin-bottom: spacing.$spacing-xx-large;
    }
    &__button {
    }
}
