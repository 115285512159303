@use "@edgetier/styles/sass/spacing";

.accordion__accordion-item-header {
    display: flex;
    align-items: center;
    min-width: 0;
    justify-content: space-between;

    .accordion__accordion-item--can-open & {
        cursor: pointer;
    }

    &__title {
        flex: 1;
        display: flex;
        align-items: center;
        padding-top: spacing.$spacing-xxx-small;
        padding-bottom: spacing.$spacing-xxx-small;
        line-height: 1.4rem * 1.3;
        min-width: 0;
    }

    & .expand-collapse-arrow {
        padding: spacing.$spacing-small;
    }
}
