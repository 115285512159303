.details-field-value {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 12px;
}
.details-field-value__copy-icon {
  pointer-events: none;
  position: absolute;
  right: 100%;
  margin-right: 10px;
  opacity: 0;
  transition: opacity 250ms ease-out;
  color: #16a085;
}
.details-field-value--can-copy {
  position: relative;
}
.details-field-value--can-copy:hover .details-field-value__copy-icon {
  opacity: 1;
}
.details-field-value__value--loading {
  flex: 1;
}
.details-field-value--can-copy .details-field-value__value {
  cursor: pointer;
  outline: 4px solid transparent;
  transition: all 250ms ease-out;
}
.details-field-value--can-copy .details-field-value__value:hover, .details-field-value--can-copy .details-field-value__value:hover * {
  background-color: #16a085;
  color: white;
  outline-color: #16a085;
}
.details-field-value--can-copy .details-field-value__value:active {
  background-color: #34495e;
  outline-color: #34495e;
}

