@use "spacing" as *;

$border-radius: 4px;

$font-size-small: 1.1rem;
$font-size-medium: 1.15rem;
$font-size-base: 1.3rem;

$heading-line-height: 40px;
$line-height-medium: $font-size-medium * 1.3;
$line-height-base: $font-size-base * 1.3;

$page-spacing: 16px;
$header-height: 55px;
$header-height-wide: $header-height + $spacing-base;
$logo-height: 80px;
$logo-padding: $spacing-base;
$table-button-height: 30px;
$button-height: 40px;
$button-small-height: 30px;
$input-line-height: 26px;
$input-height: 40px;

$field-spacing: $spacing-x-small;
$field-x-padding: $spacing-x-large !default;
$fieldset-label-line-height: 12px;
$fieldset-height: $fieldset-label-line-height + $field-padding + $input-height;

$agent-action-toggle-width: 180px;

$email-agent-action-title-padding: $spacing-x-large;
$email-agent-action-title-line-height: 40px;

$inline-label-width: 32px;

$table-heading-height: 50px;

$attachments-spacing: $spacing-small;

// Reporting.
$reporting-chart-title-height: 60px;
$reporting-chart-title-margin: $spacing-base;
$reporting-card-padding: $spacing-x-large;
$reporting-card-spacing: $spacing-large;
$reporting-card-box-shadow: 0 2px 1px rgba(0, 0, 0, 0.075);
$reporting-card-height: 55px;
$reporting-card-height-large: ($reporting-card-height * 3);
$reporting-table-padding: $spacing-small;

$attachments-spacing: $spacing-small;

// Chat.
$chat-input-height: 40px;

// Filters.
$filters-margin: $page-spacing;
$filters-height: $button-height + 2px + $filters-margin;

// Forms.
$toggle-height: 40px;

// Modals.
$modal-padding: $spacing-xx-large;

$box-box-shadow: 0 -1px 3px -1px rgba(black, 0.2), 0 2px 1px rgba(black, 0.1);
$box-border-radius: 4px;
$input-box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.1);

// Breakpoints
$slicer-breakpoints: (
    mobile: 0,
    tablet: 480px,
    desktop: 1024px,
    "desktop-wide": 1600px,
);
