@use "@edgetier/styles/sass/breakpoints";
@use "@edgetier/styles/sass/spacing";

@mixin radio() {
    .radio {
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        display: flex;
        align-items: center;
        width: 100%;
        position: relative;

        input[type="checkbox"],
        input[type="radio"] {
            position: absolute;
            opacity: 0;
            left: -99999px;
            margin: 0;
            padding: 0;
        }

        svg {
            margin-right: spacing.$spacing-x-small;
        }

        label {
            display: block;
            padding-top: spacing.$spacing-x-small;
            padding-bottom: spacing.$spacing-x-small;

            @include breakpoints.from(desktop) {
                cursor: pointer;
            }
        }

        input[disabled] + label {
            @include breakpoints.from(desktop) {
                cursor: not-allowed;
            }
        }
    }
}
