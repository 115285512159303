@use "@edgetier/client-components/dist/styles/spacing";

.select-bulk-buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .button + .button {
        margin-top: spacing.$spacing-small;
    }
}
