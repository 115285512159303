/*
  Mixin for applying ellipsis to any element
*/
.box-content--horizontal-padding {
  padding-right: 16px;
  padding-left: 16px;
}
.box-content--vertical-padding {
  padding-top: 16px;
  padding-bottom: 16px;
}

