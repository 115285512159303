@use "styles/spacing";
@use "styles/theme";
@use "styles/variables";

.customer-urls__customer-url {
    &__title {
        display: flex;
        align-items: center;
        overflow-x: hidden;
        > div:first-of-type {
            flex: 1;
            padding-left: spacing.$spacing-x-small;
        }
    }

    & + & {
        margin-top: spacing.$spacing-base;
        padding-top: spacing.$spacing-base;
    }

    &__time {
        font-variant-numeric: tabular-nums;
        @include theme.theme {
            color: theme.get(positive);
        }
    }

    &,
    .array-details-section {
        padding: 0 !important;
    }

    a {
        line-height: variables.$line-height-medium;
        font-size: variables.$font-size-small;
        display: block;
        overflow-wrap: break-word;
        word-wrap: break-word;
        -ms-word-break: break-all;
        word-break: break-all;
        word-break: break-word;
        -ms-hyphens: auto;
        -moz-hyphens: auto;
        -webkit-hyphens: auto;
        hyphens: auto;
        transition: color 150ms ease-out;
        padding-right: spacing.$spacing-large;
        padding-left: spacing.$spacing-large;

        &:hover {
            @include theme.theme {
                color: theme.get(positive);
            }
        }

        @include theme.theme {
            color: theme.get(primary);
        }
    }
}
