////
/// Breakpoint defaults all apps and component libraries
/// @group Layout
////

/// mobile breakpoint.
$mobile: 480px !default;
/// tablet breakpoint.
$tablet: 1024px !default;

/// Breakpoints - for Breakpoint Slicer and Breakpoint SASS libraries.
$slicer-breakpoints: 0 $mobile $tablet !default;
/// Breakpoint Names - for Breakpoint Slicer and Breakpoint SASS libraries.
$slicer-breakpoint-names: "mobile" "tablet" "desktop" !default;

/// Add a breakpoint for a specific screen size only
/// from the lower breakpoint (or 0) up to the provided breakpoint
@mixin at($target) {
    @if $target==mobile {
        @media (max-device-width: $mobile) {
            @content;
        }
    } @else if $target==tablet {
        @media (min-device-width: ($mobile + 1px)) and (max-device-width: $tablet) {
            @content;
        }
    } @else if $target==desktop {
        @media (min-device-width: ($tablet + 1px)) {
            @content;
        }
    }
}

/// Add a breakpoint for a minimum size
/// styles will propogate to large dimensions
@mixin from($target) {
    @if $target==mobile {
        @content;
    } @else if $target==tablet {
        @media (min-device-width: ($mobile + 1px)) {
            @content;
        }
    } @else if $target==desktop {
        @media (min-device-width: ($tablet + 1px)) {
            @content;
        }
    }
}
