@use "@edgetier/styles/sass/theme";
@use "styles/variables";
@use "styles/spacing";
@use "styles/colours";

.labeled-field {
    position: relative;

    &.field {
        margin-bottom: 0;
        background-color: transparent;
        box-shadow: none;
        @include theme.theme {
            background-color: transparent;
        }
    }
    &__label,
    .field > &__label {
        font-size: variables.$font-size-small;
        margin-bottom: spacing.$spacing-x-small;
        padding: 0;
        font-weight: 700;
        color: colours.$label;
        text-transform: uppercase;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 100%;
        overflow: hidden;
        padding-right: spacing.$spacing-x-large;
    }
}
