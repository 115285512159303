@use "root-variables" as *;
@use "spacing";
@use "theme";
@use "colours";

.header nav {
    li {
        display: inline-block;
    }
    a {
        padding: 0 spacing.$spacing-large;
        display: block;
        line-height: calc(var(--HEADER-HEIGHT) - 1px);
    }
    a.active {
        @include theme.theme {
            background-color: theme.get(background-secondary);
        }
    }
    a:not(.active) {
        &:hover svg {
            color: colours.$flat-belize-hole;
        }
        &,
        svg {
            transition: all 150ms ease-out;
        }
        svg {
            color: colours.$flat-silver;
        }
        @include theme.theme {
            color: theme.get(medium);
            &:hover {
                color: inherit;
            }
        }
    }
    svg {
        color: colours.$flat-belize-hole;
        margin-right: spacing.$spacing-small;
    }
}
