@use "styles/spacing";
@use "styles/theme";
@use "styles/variables";

.interaction-search__email {
    height: calc(100% - #{spacing.$spacing-x-large * 2});
    border-radius: variables.$box-border-radius;
    box-shadow: variables.$box-box-shadow;
    display: flex;
    flex-direction: column;
    margin: spacing.$spacing-x-large;

    .attachments,
    &__title,
    p {
        padding: spacing.$spacing-x-large;
    }

    &__redact {
        margin-top: auto;
    }

    .ellipsis-menu__trigger {
        margin-left: spacing.$spacing-large;
        margin-bottom: spacing.$spacing-xx-small;
        display: inline-block;
    }

    &__address,
    &__title strong,
    p {
        white-space: pre-wrap;
        overflow-wrap: break-word;
        word-wrap: break-word;
        -ms-word-break: break-all;
        word-break: break-word;
    }

    p {
        overflow-y: auto;
    }

    p:first-of-type {
        margin: 0;
    }

    &__title,
    .attachments {
        flex-shrink: 0;
    }

    &__title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .email-participants {
            margin-top: spacing.$spacing-base;
        }
    }

    .interaction-search__interaction-label {
        padding-left: spacing.$spacing-base;
    }

    .attachments,
    &__title {
        @include theme.theme {
            border-bottom: 1px solid theme.get(divider);
        }
    }

    &__address {
        margin-top: spacing.$spacing-base;

        @include theme.theme {
            color: theme.get(label);
        }
    }

    @include theme.theme {
        background-color: theme.get(background);
    }
}
