/*
  Mixin for applying ellipsis to any element
*/
.page-content {
  flex: 1;
  padding: 16px;
  overflow-y: auto;
}
.page-content__container {
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
}
.page-content__container--standard {
  max-width: 1200px;
}
.page-content__container--full-height {
  height: 100%;
}

