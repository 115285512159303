.scroll-with-shadows {
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.scroll-with-shadows--none {
  box-shadow: none;
}
.scroll-with-shadows--top {
  box-shadow: inset 0 27px 10px -30px rgb(200, 200, 200), inset 0 0 0 0 rgb(200, 200, 200);
}
.scroll-with-shadows--bottom {
  box-shadow: inset 0 0 0 0 rgb(200, 200, 200), inset 0 -27px 10px -30px rgb(200, 200, 200);
}
.scroll-with-shadows--between {
  box-shadow: inset 0 27px 10px -30px rgb(200, 200, 200), inset 0 -27px 10px -30px rgb(200, 200, 200);
}

