@use "@edgetier/client-components/dist/styles/colours";
@use "@edgetier/client-components/dist/styles/spacing";
@use "@edgetier/client-components/dist/styles/variables";
@use "@edgetier/client-components/dist/styles/root-variables" as *;
@use "@edgetier/styles/sass/mixins";

.page-header {
    padding-left: variables.$page-spacing;
    padding-right: variables.$page-spacing;
    border-bottom: 1px solid colours.$divider;
    display: flex;
    width: 100%;
    align-items: center;
    height: var(--HEADER-HEIGHT);
    gap: 12px;

    &__title {
        display: flex;
        flex-direction: column;
        gap: spacing.$spacing-small;
        min-width: 0;
    }

    h2 {
        margin-right: auto;
        @include mixins.overflow-ellipis();
    }

    h3 {
        margin-right: auto;
        color: colours.$label;
        font-size: 14px;
        font-weight: 700;
    }
}
