@use "styles/theme";
@use "styles/colours";
@use "styles/spacing";
@use "styles/breakpoints";
@use "styles/variables";

.chat-messages {
    &__typing-indicator div {
        @include theme.theme {
            background-color: theme.get(positive);
        }
    }
    &__message {
        a {
            text-decoration: underline;
            @include theme.theme {
                color: theme.get(primary);
            }
        }

        &__container {
            padding: spacing.$spacing-small;
            box-shadow: variables.$box-box-shadow;

            @include breakpoints.at(desktop-wide) {
                padding: spacing.$spacing-large spacing.$spacing-large spacing.$spacing-small spacing.$spacing-large;
            }
        }

        &--out &__container {
            a {
                $link-colour: white;
                color: $link-colour;
                &:hover {
                    color: darken($link-colour, 20%);
                }
            }
        }

        &--in &__container {
            @include theme.theme {
                background-color: theme.get(background);
            }
        }

        &--out &__container {
            color: white;
            .toggle-translation__button--inactive {
                @include theme.theme {
                    color: rgba(theme.get(background), 0.6);
                }
            }
            @include theme.theme {
                background-color: var(--CHAT-MESSAGE-COLOUR, theme.get(positive));
            }
        }

        &--error svg {
            @include theme.theme {
                color: theme.get(negative);
            }
        }

        &--system svg {
            @include theme.theme {
                color: theme.get(color-secondary);
            }
        }

        &--status &__container {
            box-shadow: inset 0 1px 1px rgba(black, 0.075);
            @include theme.theme {
                background-color: darken(theme.get(background-tertiary), 3%);
                color: lighten(theme.get(color), 15%);
            }
        }
    }

    &__delineator-container {
        position: relative;
        display: flex;
        justify-content: center;
    }

    &__delineator {
        flex: 1;
        margin: spacing.$spacing-xx-large 0;
        @include theme.theme {
            border-top: 1px solid theme.get(divider);
        }
    }

    &__delineator-title {
        position: absolute;
        display: inline-block;
        border-radius: 6px;
        padding: spacing.$spacing-small;
        top: 13px;
        background-color: colours.$divider-colour;

        @include theme.theme {
            color: lighten(theme.get(color), 15%);
        }
    }
}
