@use "styles/spacing";
@use "styles/theme";

.navigation-item-notification-count {
    font-weight: bold;
    text-align: center;
    box-shadow: 0 1px 5px rgba(black, 0.2);
    border-radius: 3px;
    padding: spacing.$spacing-xxx-small spacing.$spacing-xx-small;
    line-height: normal;

    @include theme.theme {
        background-color: theme.get(negative);
        color: theme.get(background);
    }
}
