@use "styles/colours";

.accordion__accordion-item {
    border-left: 3px solid transparent;
    transition: border-color 250ms ease-out;

    &--is-open {
        border-left-color: colours.$positive;
    }
}
