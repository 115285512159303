/*
  Mixin for applying ellipsis to any element
*/
.grid__button-cell {
  width: 40px;
}
.grid__button-cell .grid__loading-cell {
  width: 40px;
}
.grid__button-cell + .grid__button-cell, .grid__button-cell + .grid__expand-button {
  padding-left: 0;
}

td:not(.grid__button-cell) + .grid__button-cell {
  border-left: 1px solid rgb(241.8727272727, 244.6363636364, 245.3272727273);
}

