@use "styles/colours";
@use "styles/spacing";
@use "styles/theme";

.form-submission-star-field {
    &__value {
        display: flex;
        padding: spacing.$spacing-base;
        font-size: 1.6rem;

        &--filled {
            color: colours.$flat-sunflower;
        }
        &--empty {
            @include theme.theme {
                color: theme.get(label);
            }
        }

        svg {
            flex: 1;
        }
    }
}
