@use "styles/spacing";

.details-section-title {
    display: flex;
    justify-content: space-between;
    padding-bottom: spacing.$spacing-x-large;
    align-items: center;
    padding-right: spacing.$spacing-large;
    padding-left: spacing.$spacing-large;
}
