@use "@edgetier/styles/sass/theme";
@use "styles/spacing";

.toggle {
    align-items: center;
    display: flex;
    flex-shrink: 0;

    &__state {
        padding-left: spacing.$spacing-x-small;
        display: flex;
        justify-content: flex-end;
        > div:nth-of-type(2) {
            margin-left: -50%;
        }

        &--disabled {
            padding-left: 0;
            padding-right: spacing.$spacing-x-small;
        }
    }

    &__label {
        display: inline-block;
        margin-left: 8px;
    }

    .react-toggle-thumb {
        @include theme.theme {
            background-color: theme.get(background);
            border: 3px solid theme.get(label);
        }
    }

    .react-toggle--checked .react-toggle-thumb {
        @include theme.theme {
            border: 3px solid theme.get(positive);
        }
    }

    .react-toggle-track,
    .react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
        @include theme.theme {
            background-color: theme.get(label);
        }
    }

    .react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track,
    .react-toggle--checked .react-toggle-track {
        @include theme.theme {
            background-color: theme.get(positive);
        }
    }

    .react-toggle--focus .react-toggle-thumb {
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
    }

    .react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
    }
}
