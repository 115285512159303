@use "styles/spacing";

.wrap-up-details {
    .details-section-title {
        display: grid;
        grid-auto-flow: row;

        .button {
            grid-row: 1;
            grid-column: 2;
            margin: 0 0 0 spacing.$spacing-large;
        }
    }
}
