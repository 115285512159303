@use "colours";
@use "theme";
@use "spacing";
@use "variables";

.reporting {
    padding-bottom: 0;

    &-chart {
        h3 {
            line-height: variables.$reporting-chart-title-height;
            padding-left: variables.$reporting-card-padding;
        }

        h3 span {
            font-weight: normal;
            color: colours.$flat-asbestos;
        }
    }

    &-chart__heading {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-right: variables.$reporting-card-padding;

        .field-inline {
            width: 200px;

            @include theme.theme {
                border: 1px solid theme.get(background-tertiary);
            }
        }

        .field-inline + .field-inline {
            margin-left: spacing.$spacing-base;
        }

        form {
            display: flex;
        }
    }

    &-chart,
    &-card {
        border-radius: variables.$border-radius;

        @include theme.theme {
            background-color: theme.get(background);
        }
    }

    &-card-large {
        height: variables.$reporting-card-height-large;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .reporting-card__value {
            font-size: 22px;
            padding: spacing.$spacing-base 0;
            font-weight: bold;
        }

        svg,
        span {
            font-size: 18px;
        }

        span {
            @include theme.theme {
                color: theme.get(label);
            }
        }
    }

    &-card {
        padding: variables.$reporting-card-padding;

        svg,
        > svg {
            @include theme.theme {
                color: theme.get(primary);
            }
        }
    }

    &-chart-container {
        height: calc(100% - #{variables.$reporting-chart-title-height});

        .spinner {
            height: 100%;
        }
    }

    &-chart,
    &-card {
        box-shadow: variables.$reporting-card-box-shadow;
    }
}

.recharts-cartesian-axis-ticks text,
.recharts-label {
    @include theme.theme {
        fill: theme.get(color);
    }
}

.recharts-label {
    font-weight: bold;
}

.recharts-cartesian-axis-line {
    @include theme.theme {
        stroke: theme.get(divider);
    }
}

.recharts-cartesian-axis-tick-line {
    stroke: none;
}

.recharts-cartesian-grid-horizontal line,
.recharts-cartesian-grid-vertical line {
    @include theme.theme {
        stroke: theme.get(divider);
    }
}

.recharts-bar-rectangle:hover path {
    @include theme.theme {
        fill: theme.get(pop-up-background);
    }
}

.recharts-rectangle.recharts-tooltip-cursor {
    opacity: 0.25;

    @include theme.theme {
        fill: theme.get(pop-up-background);
    }
}

.chart-tooltip {
    padding: spacing.$spacing-large;
    border-radius: 2px;
    box-shadow: 0 2px 2px rgba(black, 0.25);
    display: flex;
    align-items: center;

    div {
        display: inline-block;
        line-height: variables.$line-height-base;
    }

    &-value {
        font-size: 2rem;
        font-weight: bold;
        padding-right: spacing.$spacing-base;
    }

    &-label {
        max-width: 130px;
        border-left: 1px dashed rgba(white, 0.2);
        padding-left: spacing.$spacing-base;
    }

    &--multiple {
        text-align: center;

        .chart-tooltip-value div {
            display: block;
            font-size: variables.$font-size-small;
            padding-bottom: spacing.$spacing-xxx-small;
        }

        .chart-tooltip-value div div {
            display: inline-block;
            margin-left: spacing.$spacing-xxx-small;
        }
    }

    @include theme.theme {
        color: theme.get(pop-up-color);
        background-color: theme.get(pop-up-background);
    }
}

.recharts-bar + .recharts-bar {
    .recharts-bar-rectangle {
        stroke: white;
        stroke-width: 1px;
    }
}
