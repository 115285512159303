/*
  Mixin for applying ellipsis to any element
*/
.grid__pagination-numbers {
  height: 40px;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.1rem;
  margin: 0 1px;
  background-color: white;
}

