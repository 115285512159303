@use "@edgetier/styles/sass/spacing";
@use "@edgetier/styles/sass/colours";
@use "sass:math";

$animation-duration: 2s !default;
$count: 25 !default;
$page-spacing: spacing.$spacing-x-large !default;
$animation-delay: math.div($animation-duration, $count) !default;

@mixin spinner() {
    .spinner {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: $page-spacing;

        svg {
            height: 45px;
            width: 45px;
        }

        svg path,
        svg rect {
            fill: colours.$flat-peter-river;
        }

        &--full-page {
            height: 100%;
            svg {
                height: 85px;
                width: 85px;
            }
        }

        circle {
            animation-name: flash;
            animation-duration: $animation-duration;
            animation-fill-mode: both;
            animation-iteration-count: infinite;
            fill: #c0392b;
            shape-rendering: geometricPrecision;
            transform-origin: center center;
        }

        circle:nth-of-type(1) {
            animation-delay: 0;
        }

        circle:nth-of-type(2),
        circle:nth-of-type(6) {
            animation-delay: $animation-delay;
        }

        circle:nth-of-type(3),
        circle:nth-of-type(7),
        circle:nth-of-type(12) {
            animation-delay: 2 * $animation-delay;
        }

        circle:nth-of-type(4),
        circle:nth-of-type(8),
        circle:nth-of-type(13),
        circle:nth-of-type(16) {
            animation-delay: 3 * $animation-delay;
        }

        circle:nth-of-type(5),
        circle:nth-of-type(9),
        circle:nth-of-type(17),
        circle:nth-of-type(20) {
            animation-delay: 4 * $animation-delay;
        }

        circle:nth-of-type(10),
        circle:nth-of-type(21) {
            animation-delay: 5 * $animation-delay;
        }

        circle:nth-of-type(11),
        circle:nth-of-type(14),
        circle:nth-of-type(22),
        circle:nth-of-type(26) {
            animation-delay: 6 * $animation-delay;
        }

        circle:nth-of-type(15),
        circle:nth-of-type(18),
        circle:nth-of-type(23),
        circle:nth-of-type(27) {
            animation-delay: 7 * $animation-delay;
        }

        circle:nth-of-type(19),
        circle:nth-of-type(24),
        circle:nth-of-type(28) {
            animation-delay: 8 * $animation-delay;
        }

        circle:nth-of-type(25),
        circle:nth-of-type(29) {
            animation-delay: 9 * $animation-delay;
        }

        circle:nth-of-type(30) {
            animation-delay: 10 * $animation-delay;
        }

        @keyframes flash {
            0%,
            100% {
                fill: colours.$flat-clouds;
                transform: scale(0.95, 0.95);
            }

            50% {
                fill: colours.$flat-silver;
                transform: scale(1, 1);
            }
        }
    }
}
