@use "styles/colours";
@use "styles/variables";

.grid__button-cell {
    width: variables.$button-height;

    .grid__loading-cell {
        width: variables.$button-height;
    }

    & + &,
    & + .grid__expand-button {
        padding-left: 0;
    }
}

td:not(.grid__button-cell) + .grid__button-cell {
    border-left: 1px solid colours.$background-secondary;
}
