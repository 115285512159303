@use "@edgetier/styles/sass/colours";
@use "@edgetier/styles/sass/spacing";

.dynamic-form__radios {
    .radio label {
        padding-left: spacing.$field-padding;
        padding-right: spacing.$field-padding;
        display: block;
        width: 100%;
    }
    .radio + .radio {
        border-top: 1px dashed rgba(black, 0.05);
    }
    svg {
        color: colours.$flat-asbestos;
    }
    input:focus + label {
        outline: 2px solid colours.$flat-asbestos;
    }
}
