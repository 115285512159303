@use "@edgetier/styles/sass/breakpoints";
@use "@edgetier/styles/sass/colours";
@use "@edgetier/styles/sass/spacing";

.dynamic-form__buttons {
    margin-top: spacing.$spacing-xx-small;

    &__container {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        margin: -#{spacing.$spacing-xx-small};
    }

    &__button {
        padding: spacing.$spacing-xx-small;

        label {
            border: 2px solid #d9d9d9;
            transition: all 250ms ease-out;
            padding: spacing.$spacing-small spacing.$spacing-large;
            border-radius: 12px;
            font-size: 12px;
            display: block;
            box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);

            &:hover {
                background-color: rgba(black, 0.1);
            }

            @include breakpoints.from(desktop) {
                cursor: pointer;
            }
        }
    }

    &--dirty .dynamic-form__buttons__button--not-selected label {
        color: rgba(black, 0.3);
    }

    &__button--selected label {
        background-color: #fff;
    }

    input[type="radio"] {
        position: absolute;
        opacity: 0;
    }

    input[type="radio"]:focus + label {
        border: 2px solid colours.$flat-asbestos;
    }

    .dynamic-form--single-field & .dynamic-form__buttons__button {
        width: 100%;
    }
}
