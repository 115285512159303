.grid__row--active {
  outline: 1px solid #16a085;
}
.grid__row--active,
.grid__row--active td {
  transition: all 150ms ease-out;
}
.grid__row--active td {
  background-color: rgba(22, 160, 133, 0.075);
}
.grid__row--is-clickable {
  cursor: pointer;
}
.grid__row--fixed {
  font-weight: bold;
  color: #3498db;
}
.grid__row--fixed + tr:not(.grid__row--fixed) {
  border-top: 1px solid rgba(52, 152, 219, 0.3);
}
.grid__row--warning {
  background-color: rgb(250.5616438356, 221.897260274, 218.9383561644);
}
.grid__row--warning:hover {
  background-color: rgb(248.8849315068, 209.3917808219, 205.3150684932);
}
.grid__row--new-activity {
  background-color: rgb(251.6794520548, 230.2342465753, 228.0205479452);
}
.grid__row--new-activity:hover {
  background-color: rgb(249.4438356164, 213.5602739726, 209.8561643836);
}

.grid tbody tr:not(.grid__empty):not(.grid__row--is-loading).grid__row--highlighted {
  background-color: rgb(242.5991631799, 248.7079497908, 252.8008368201);
}
.grid tbody tr:not(.grid__empty):not(.grid__row--is-loading).grid__row--highlighted:hover {
  background-color: rgb(229.6037656904, 242.1142259414, 250.4962343096);
}
.grid tbody tr:not(.grid__empty):not(.grid__row--is-loading).grid__row--highlighted:last-of-type {
  border-bottom: 2px solid #e74c3c;
}

