@use "styles/spacing";
@use "styles/colours";

.details-field-title {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: spacing.$spacing-large;
    padding-right: spacing.$spacing-large;
    color: colours.$positive;
    &__loading {
        flex: 1;
    }
}
