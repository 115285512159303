.information-field {
  padding: 16px;
  margin-bottom: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 4px;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
}
.information-field .link {
  text-decoration: underline;
  color: #3498db;
}

