@use "styles/variables";
@use "styles/colours";
@use "styles/spacing";

.grid {
    &--is-in-box {
        table tr + tr td {
            border-top: 1px dashed colours.$divider;
        }

        th {
            background-color: colours.$background;
        }

        th,
        td {
            padding: spacing.$spacing-large spacing.$spacing-base;
        }

        tr td:first-of-type,
        tr th:first-of-type {
            padding-left: (variables.$box-padding * 2);
        }

        tr td:last-of-type,
        tr th:last-of-type {
            padding-right: (variables.$box-padding * 2);
        }
    }

    &--sticky-header {
        table {
            border-collapse: separate;
        }

        thead {
            position: sticky;
            top: 0;
            z-index: 10;
        }
    }

    &:not(.grid--is-in-box) {
        position: relative;
        box-shadow: variables.$box-box-shadow;
        border-radius: variables.$box-border-radius;
        background-color: colours.$background;

        th {
            background-color: colours.$background-tertiary;
            line-height: variables.$line-height-base;
        }

        td {
            border-top: 1px solid colours.$background-secondary;
        }

        thead tr:last-of-type th,
        td {
            padding: spacing.$spacing-small;
            vertical-align: middle;
        }
    }

    table {
        width: 100%;
    }

    th {
        font-weight: bold;
        text-align: left;
        text-transform: uppercase;
        font-size: variables.$font-size-small;
        height: variables.$table-heading-height;
        vertical-align: middle;
        color: colours.$label;
        border-bottom: 1px solid colours.$divider;
    }

    th,
    td {
        vertical-align: middle;
    }

    tbody tr {
        height: variables.$table-heading-height;
    }

    tbody tr:not(.grid__empty):not(.grid__row--is-loading):not(.grid__row--new-activity):hover {
        background-color: lighten(colours.$flat-clouds, 3%);
    }

    tr .pages-table-button-first {
        border-left: 3px solid colours.$background-secondary;
    }

    .pages-table-button + .pages-table-button {
        padding-left: 0;
    }

    tr td.pages-table-button {
        width: variables.$button-height;
    }

    tr th.pages-table-button {
        background-color: colours.$background-secondary;
    }

    thead tr:first-of-type th:first-of-type {
        border-top-left-radius: variables.$border-radius;
    }

    thead tr:first-of-type th:last-of-type {
        border-top-right-radius: variables.$border-radius;
    }

    tfoot tr:last-of-type th:first-of-type {
        border-bottom-left-radius: variables.$border-radius;
    }

    tfoot tr:last-of-type th:last-of-type {
        border-bottom-right-radius: variables.$border-radius;
    }

    &--has-buttons &__loading-cell {
        min-height: variables.$table-button-height;
    }

    .table {
        &--has-horizontal-scroll {
            position: relative;
            background-color: colours.$background;

            thead {
                tr {
                    position: relative;

                    th {
                        z-index: 10;

                        &:not(:first-child) {
                            min-width: 120px;
                        }

                        &:first-child {
                            position: sticky;
                            left: 0;
                            z-index: 20;
                        }
                    }
                }
            }

            tbody {
                th {
                    position: sticky;
                    left: 0;
                    z-index: 2;
                    text-align: left;
                }

                th,
                td {
                    white-space: nowrap;

                    &:first-child {
                        background-color: colours.$background;
                        position: sticky;
                        left: 0;
                        z-index: 1;
                    }
                }

                tr {
                    &:hover {
                        background-color: lighten(colours.$flat-clouds, 3%);

                        & > td:first-child {
                            background-color: lighten(colours.$flat-clouds, 3%);
                        }
                    }
                }
            }
        }
    }
}
