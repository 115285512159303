@use "styles/spacing";
@use "styles/colours";

.handle-query-button {
    &__in-progress,
    &__is-handled {
        display: flex;
        flex-direction: column;
        gap: spacing.$spacing-large;
        text-align: center;

        & p {
            color: colours.$flat-asbestos;
            margin-top: 0;
        }
    }

    &__in-progress,
    &__is-handled {
        & svg {
            color: colours.$flat-carrot;
        }
    }

    &__buttons {
        display: flex;
        flex-direction: row;
        gap: spacing.$spacing-x-small;
        width: 100%;

        & button:last-child {
            margin-left: auto;
        }
    }
}
