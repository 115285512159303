.application__authenticated-routes {
    display: flex;

    &,
    &__pages,
    &__page {
        height: 100%;
    }

    &,
    &__pages {
        overflow-x: hidden;
    }

    &__pages {
        flex: 1;
        position: relative;
    }

    &__page {
        will-change: transform, opacity;
        display: flex;
        flex: 1;
    }
}
