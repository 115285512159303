@use "styles/spacing";
@use "styles/colours";

.array-details-section {
    &:first-of-type {
        margin-top: spacing.$spacing-xx-small;
    }

    & + & {
        border-top: 1px solid colours.$background-secondary;
    }

    .accordion__accordion-item-header__title {
        display: flex;
        justify-content: space-between;
    }

    .tooltip-warning__trigger {
        margin-left: auto;
        margin-right: spacing.$spacing-base;
    }
}
