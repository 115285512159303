@use "styles/theme";

::-webkit-scrollbar {
    width: 12px;
    height: 6px;
}

::-webkit-scrollbar-track {
    @include theme.theme {
        background-color: inherit;
    }
}

::-webkit-scrollbar-thumb {
    border: 3px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 10px;

    @include theme.theme {
        background-color: theme.get(scroll);
    }
}

.field ::-webkit-scrollbar-track {
    border-left: none;
}
