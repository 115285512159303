.interaction-icon {
  display: flex;
  width: 24px;
  height: 24px;
  align-items: center;
  justify-content: center;
}
.interaction-icon__automated {
  --fa-primary-color: #bdc3c7;
  --fa-secondary-color: #95a5a6;
  --fa-secondary-opacity: 1;
}
.interaction-icon__call {
  color: #9b59b6;
}
.interaction-icon__chat, .interaction-icon__messages {
  --fa-primary-color: #3498db;
  --fa-secondary-color: #2980b9;
  --fa-secondary-opacity: 1;
}
.interaction-icon__email {
  --fa-primary-color: #16a085;
  --fa-secondary-color: #2ecc71;
  --fa-secondary-opacity: 0.4;
}
.interaction-icon__call-in, .interaction-icon__email-in {
  --fa-primary-color: #16a085;
  --fa-secondary-color: #16a085;
  --fa-secondary-opacity: 0.8;
}
.interaction-icon__call_out, .interaction-icon__email-out {
  --fa-primary-color: #e74c3c;
  --fa-secondary-color: #e74c3c;
  --fa-secondary-opacity: 0.8;
}
.interaction-icon__external {
  --fa-primary-color: #2980b9;
  --fa-primary-opacity: 0.4;
  --fa-secondary-color: #2980b9;
  --fa-secondary-opacity: 1;
}
.interaction-icon__notes {
  --fa-primary-color: #95a5a6;
  --fa-secondary-color: #bdc3c7;
  --fa-secondary-opacity: 1;
}
.interaction-icon__survey {
  --fa-primary-color: #2c3e50;
  --fa-secondary-color: #2c3e50;
  --fa-secondary-opacity: 0.4;
}
.interaction-icon__social-media {
  --fa-primary-color: #2980b9;
  --fa-secondary-color: #3498db;
  --fa-secondary-opacity: 1;
}

