@use "styles/variables";

.box-content {
    &--horizontal-padding {
        padding-right: variables.$box-padding;
        padding-left: variables.$box-padding;
    }

    &--vertical-padding {
        padding-top: variables.$box-padding;
        padding-bottom: variables.$box-padding;
    }
}
