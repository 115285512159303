/*
  Mixin for applying ellipsis to any element
*/
.box-header {
  margin-top: 16px;
  margin-right: 16px;
  margin-left: 16px;
  padding: 16px;
  background-color: rgb(241.8727272727, 244.6363636364, 245.3272727273);
  border-bottom: 1px solid #ecf0f1;
  position: relative;
  border-radius: 6px;
}

