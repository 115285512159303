.lazy-wrapper {
    min-height: 0;
    transition: min-height 0.25s linear;

    &--placeholder {
        min-height: 50vh;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
