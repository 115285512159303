@use "styles/spacing";

.form-submission-textarea-field {
    .blank-field {
        padding: spacing.$spacing-xxx-small spacing.$spacing-large;
    }

    .details-field-value {
        margin-left: spacing.$spacing-large;
        margin-top: spacing.$spacing-xxx-small;
    }

    p {
        margin: 0;
    }
}
