@use "styles/spacing";
@use "styles/theme";
@use "styles/variables";
@use "sass:math";

$icon-width: 26px;

.interaction-event {
    display: flex;
    margin-top: spacing.$spacing-small;
    margin-bottom: spacing.$spacing-small;
    align-items: center;
    position: relative;

    @include theme.theme {
        background-color: theme.get(background-secondary);
    }

    .interaction-event-time,
    .interaction-event-name {
        flex: 0 1 calc(50% - #{math.div($icon-width, 2)});
    }

    &__icon {
        flex: 0 0 $icon-width;
        height: $icon-width;
        text-align: center;
        margin: 0 spacing.$spacing-base;
        border-radius: 4px;
        box-shadow: inset 0 1px 2px rgba(black, 0.3);

        svg {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        @include theme.theme {
            color: theme.get(background);
        }
    }

    &--primary &__icon {
        @include theme.theme {
            background-color: theme.get(primary);
        }
    }

    &--negative &__icon {
        @include theme.theme {
            background-color: theme.get(negative);
        }
    }

    &--neutral &__icon {
        @include theme.theme {
            background-color: theme.get(neutral);
        }
    }

    &--positive &__icon {
        @include theme.theme {
            background-color: theme.get(positive);
        }
    }
}
