@use "styles/colours";

.formatted-date-time {
    .formatted-date {
        display: inline-block;
    }
    &__time--different-colour {
        color: colours.$medium;
    }
}
