@use "@edgetier/client-components/dist/styles/colours";
@use "@edgetier/client-components/dist/styles/spacing";

.popover-footer {
    display: flex;
    align-items: center;
    padding: spacing.$spacing-x-large spacing.$spacing-large;
    border-top: 1px solid colours.$flat-clouds-2;
    height: 54px;
}
