.metric-box-actions-menu {
  position: absolute;
  top: 0;
  right: 0;
}
.metric-box-actions-menu .ellipsis-menu__trigger {
  padding: 8px;
}
.metric-box-actions-menu .ellipsis-menu__trigger svg {
  margin: 0;
  font-size: 16px;
  color: #2c3e50;
}

