@use "@edgetier/client-components/dist/styles/colours";
@use "@edgetier/client-components/dist/styles/spacing";
@use "@edgetier/client-components/dist/styles/variables";

.metric-box {
    text-align: center;

    &__header {
        background-color: colours.$background-secondary;
        align-items: center;
        border-radius: 6px;
        margin-bottom: spacing.$spacing-base;
        height: 75px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
    }

    &__tooltip {
        text-align: center;
        line-height: variables.$line-height-base;
    }

    svg {
        color: colours.$primary;
        font-size: 22px;
        margin-bottom: spacing.$spacing-base;
    }

    .box-content {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    &__values {
        flex: 1;
        display: flex;
        justify-content: center;
        flex-direction: column;
    }

    &__agent-value {
        font-weight: bold;
        font-size: 22px;
    }

    &__overall-value {
        margin-top: spacing.$spacing-xxx-small;
        font-size: variables.$font-size-small;
    }

    &__title {
        padding: 0 spacing.$spacing-xxx-small;
    }
}
