@use "styles/theme";

.interaction-event-time {
    font-variant-numeric: tabular-nums;
    text-align: right;

    @include theme.theme {
        color: theme.get(label);
    }
}
