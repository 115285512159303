@use "styles/variables";
@use "styles/spacing";
@use "styles/colours";

.box-subheading {
    font-size: variables.$font-size-small;
    margin-top: spacing.$spacing-x-small;
    color: darken(colours.$label, 10%);
    font-weight: normal;
}
