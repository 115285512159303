/*
  Mixin for applying ellipsis to any element
*/
.box {
  box-shadow: 0 -1px 3px -1px rgba(0, 0, 0, 0.2), 0 2px 1px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  background-color: white;
  overflow: auto;
}

