$border-radius: 4px;
$font-size-small: 1.1rem !default;
$font-size-base: 1.3rem !default;
$line-height-base: $font-size-base * 1.3 !default;
$slices: (
    mobile: 0,
    tablet: 480px,
    desktop: 1024px,
    "desktop-wide": 1600px,
);

// Charts
$tooltip-box-shadow: 0 2px 1px rgba(black, 0.2);
