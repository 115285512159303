.search-help {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  padding-right: 10px;
  display: flex;
  align-items: center;
}
.search-help__content {
  width: 300px;
  line-height: 18px;
  padding: 8px;
  font-weight: normal;
}
.search-help__content p:first-of-type {
  margin-top: 0;
}
.search-help__content ul {
  display: block;
  list-style-type: disc;
  margin-left: 0;
  margin-right: 0;
  padding-left: 28px;
}
.search-help__content li {
  margin-top: 2px;
}
.search-help__content code {
  font-size: 10px;
  font-family: Monaco, Consolas, monospace;
  background-color: rgba(0, 0, 0, 0.3);
  outline: 2px solid rgba(0, 0, 0, 0.3);
}

