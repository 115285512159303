@use "styles/spacing";

.field-label-tooltip {
    .field > & {
        display: flex;
        flex-direction: row;
        gap: spacing.$spacing-small;
    }

    &__content {
        line-height: 18px;
        font-weight: bold;

        p:first-of-type {
            margin-top: 0;
        }

        ul {
            display: block;
            list-style-type: disc;
            margin-left: 0;
            margin-right: 0;
            padding-left: spacing.$spacing-xx-large;
        }

        li {
            margin-top: spacing.$spacing-xxx-small;
        }

        code {
            font-size: 10px;
            font-family: Monaco, Consolas, monospace;
            background-color: rgba(black, 0.3);
            outline: 2px solid rgba(black, 0.3);
        }
    }
}
