@use "styles/spacing";

.ellipsis-menu {
    &__item--has-link {
        padding: 0;
    }

    &__item-link {
        width: 100%;
        padding: 0 spacing.$spacing-large;
    }
}
