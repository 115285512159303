@use "styles/colours";

.boolean-icon {
    text-align: center;
    font-size: 16px;

    &--true {
        color: colours.$positive;
    }

    &--false {
        color: colours.$negative;
    }
}
