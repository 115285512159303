.theme-dark .toggle__state--enabled {
  color: #16a085;
}
.theme-light .toggle__state--enabled {
  color: #16a085;
}
.theme-contrast .toggle__state--enabled {
  color: #16a085;
}

