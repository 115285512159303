@use "@edgetier/client-components/dist/styles/spacing";
@use "@edgetier/client-components/dist/styles/colours";

.remove-role {
    p {
        margin-bottom: spacing.$spacing-x-large;
    }
    &__button {
        display: flex;

        .button {
            margin-left: auto;
        }
        .button--negative {
            background-color: colours.$negative;
        }
    }
}
