@use "styles/variables";
@use "styles/colours";

.box-header {
    margin-top: variables.$box-padding;
    margin-right: variables.$box-padding;
    margin-left: variables.$box-padding;
    padding: variables.$box-padding;
    background-color: colours.$background-secondary;
    border-bottom: 1px solid colours.$background-tertiary;
    position: relative;
    border-radius: 6px;
}
