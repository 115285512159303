@use "styles/spacing";
@use "styles/colours";
@use "styles/variables";

.details-section {
    border-radius: variables.$box-border-radius;
    box-shadow: variables.$box-box-shadow;
    margin-bottom: spacing.$spacing-base;
    padding-top: spacing.$spacing-large;
    padding-bottom: spacing.$spacing-large;
    background-color: colours.$background;

    .accordion__accordion-item-header {
        padding-right: spacing.$spacing-large;
        padding-left: spacing.$spacing-large - 3px;
    }

    table {
        width: 100%;
    }

    h5,
    td {
        line-height: 16px;
    }

    tr + tr td {
        border-top: 1px solid colours.$background-secondary;
    }

    td,
    .accordion__accordion-item-header__title {
        padding-top: spacing.$spacing-x-small;
        padding-bottom: spacing.$spacing-x-small;
    }

    td {
        vertical-align: middle;

        &:not([colspan]):first-of-type {
            width: 40%;
            padding-right: spacing.$spacing-x-small;
        }

        &:last-of-type {
            overflow-wrap: break-word;
            word-wrap: break-word;
            -ms-word-break: break-all;
            word-break: break-all;
            word-break: break-word;
            -ms-hyphens: auto;
            -moz-hyphens: auto;
            -webkit-hyphens: auto;
            hyphens: auto;
        }
    }

    table + h5 {
        margin-top: spacing.$spacing-large;
        padding-top: spacing.$spacing-large;
    }

    button {
        margin-top: spacing.$spacing-large;
        margin-left: spacing.$spacing-large;
    }
}
