@use "styles/colours";
@use "styles/spacing";

.grid__table-footer {
    padding: spacing.$spacing-small;
    display: flex;
    justify-content: flex-end;

    .grid--is-in-box & {
        border-top: 1px solid colours.$divider;
    }
}

.grid:not(.grid--is-in-box) .grid__table-footer {
    box-shadow: inset 0 1px 2px -1px rgba(black, 0.1);
    background-color: colours.$background-tertiary;
}
