@use "@edgetier/styles/sass/breakpoints";

.dynamic-form__rating {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    position: relative;

    input[type="radio"] {
        position: absolute;
        opacity: 0;
    }

    &__item {
        flex: 1;
        text-align: center;
        label {
            display: block;
            @include breakpoints.from(tablet) {
                cursor: pointer;
            }
        }
    }
}
