@use "styles/spacing";

.form-submission-select-multiple-field {
    &--single,
    .blank-field {
        padding: spacing.$spacing-xxx-small spacing.$spacing-large;
    }

    ul {
        list-style-position: inside;
        padding: spacing.$spacing-base;
        margin-left: 15px;
        list-style-type: disc;
    }
}
