@use "@edgetier/styles/sass/colours";
@use "@edgetier/styles/sass/spacing";
@use "@edgetier/styles/sass/variables";

.callout {
    display: grid;
    grid-template-columns: min-content auto;
    padding: spacing.$spacing-x-large;
    border-radius: variables.$border-radius;
    border: 1px solid;

    &:has(.callout__icon) {
        gap: spacing.$spacing-small;
    }

    &:not(:has(.callout__icon)) {
        .callout__title {
            margin-bottom: spacing.$spacing-small;
        }
    }

    &__icon {
        grid-column: 1;
        grid-row: 1;
    }

    &__title {
        grid-column: 2;
        grid-row: 1;
        font-weight: 700;
    }

    &__content {
        grid-column: 2;
        grid-row: 2;
        display: flex;
        flex-direction: column;
        gap: spacing.$spacing-small;

        p {
            margin-top: 0;
        }
    }

    // Variant styling
    &--neutral {
        background: colours.$flat-white;
        border-color: colours.$flat-silver;
    }

    &--info {
        background: lighten(colours.$flat-belize-hole, 45%);
        border-color: colours.$flat-belize-hole;

        .callout__icon {
            color: colours.$flat-belize-hole;
        }
    }

    &--warning {
        background: lighten(colours.$flat-sunflower, 40%);
        border-color: darken(colours.$flat-sunflower, 5%);

        .callout__icon {
            color: darken(colours.$flat-sunflower, 5%);
        }
    }

    &--error {
        background: lighten(colours.$flat-alizarin, 37%);
        border-color: colours.$flat-alizarin;

        .callout__icon {
            color: colours.$flat-alizarin;
        }
    }
}
