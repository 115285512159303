@use "@edgetier/client-components/dist/styles/colours";
@use "@edgetier/client-components/dist/styles/spacing";
@use "@edgetier/client-components/dist/styles/variables";
@use "@edgetier/client-components/dist/styles/forms";

.select {
    $control-padding: spacing.$spacing-small;

    &__menu {
        background-color: colours.$background;

        .field {
            border: 1px solid colours.$divider;
        }

        > .button {
            margin-left: auto;
        }

        &__container {
            display: none;
        }
    }

    &__controls {
        display: flex;
    }

    &__clear:first-of-type {
        svg {
            transition: color 250ms ease-out;
        }

        &:hover svg {
            color: colours.$negative;
        }
    }

    &__trigger {
        cursor: pointer;
        line-height: variables.$button-height;
        display: flex;
        padding-left: spacing.$field-padding;
        padding-right: 0 !important;
        justify-content: space-between;
        align-items: center;
        white-space: nowrap;
        overflow-x: hidden;
        text-overflow: ellipsis;

        &__container {
            display: flex;
            align-items: center;
            padding-right: spacing.$spacing-small;
        }

        .field-inline & {
            padding-left: variables.$inline-label-width;
        }

        svg {
            color: colours.$label;
        }

        &--item-selected {
            &::placeholder {
                color: colours.$flat-midnight-blue !important;
            }
        }
    }

    &__preview-container {
        overflow-x: hidden;
    }

    &--is-disabled {
        opacity: 0.35;
        cursor: not-allowed;
    }

    &--has-border {
        border: 1px solid colours.$divider;
        border-radius: variables.$border-radius;
    }

    &__dummy-input {
        display: none;
    }
}
