.scroll-with-shadows {
    transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);

    &--none {
        box-shadow: none;
    }

    &--top {
        box-shadow:
            inset 0 27px 10px -30px rgb(200 200 200 / 1),
            inset 0 0 0 0 rgb(200 200 200 / 1);
    }

    &--bottom {
        box-shadow:
            inset 0 0 0 0 rgb(200 200 200 / 1),
            inset 0 -27px 10px -30px rgb(200 200 200 / 1);
    }

    &--between {
        box-shadow:
            inset 0 27px 10px -30px rgb(200 200 200 / 1),
            inset 0 -27px 10px -30px rgb(200 200 200 / 1);
    }
}
